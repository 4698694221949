export const GENERAL_STATUS = {
    Completado: 1,
    Pago_finalizado: 2,
    Pago_pendiente: 3,
    Envio_pendiente: 4,
    Retirado: 5,
    Retiro_pendiente: 6,
    Pago_y_envio_retiro_pendiente: 7,
    Entregado: 8,
    Cancelado: 9
}

export const TICKET_STATUS = {
    Pago_finalizado: 2,
    Pago_endiente: 3,
    Cancelado: 9
}

export const TICKET_PAYMENT_METHOD = {
    Efectivo: 1,
    Transferencia: 2,
    Mercado_Pago: 3,
    Mercado_Libre: 4
}

export const SHIPPING_TYPE = {
    Envio_a_domicilio: 1,
    Retiro_en_local: 2,
    Envio_por_MercadoLibre: 3
}

export const SHIPPING_STATUS = {
    Envio_pendiente: 4,
    Retirado: 5,
    Retiro_pendiente: 6,
    Entregado: 8,
    Cancelado: 9
}

export const PRODUCT_TYPE = {
    "Producto": 1,
    "Accesorio": 2
}

export const GAMES_CATEGORIES = {
    1: "Euro",
    2: "Party",
    3: "Familiar",
    4: "Abstracto",
    5: "Temático",
    6: "Investigación",
}

export const GAMES_DIFFICULTY= {
    1: "Fácil",
    2: "Moderada",
    3: "Alta"
}