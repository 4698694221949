import React, { Component } from 'react';

import config from '../config.json';
import HeaderTop from '../components/HeaderTop';
import SectionFooter from '../components/SectionFooter';
import SectionScrollToUp from '../components/SectionScrollToUp';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import {GAMES_CATEGORIES, GAMES_DIFFICULTY} from '../utils/enums.js'
import '@firebase/storage';
import {convertToMoney} from  '../utils/utils';

class ShopItemPage extends Component {

	constructor(){
        super();
        const widthWindow = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.state = {
            code: -1,
            type: -1,
            quantity: 1,
            item: '',
            relatedProducts: [],
            changeDataCart: 0,
            stock: 0,
            loaded: false,
            images: {
                1: '../images/default.jpg',
                2: '../images/default.jpg',
                3: '../images/default.jpg',
                4: '../images/default.jpg',
                5: '../images/default.jpg',
                6: '../images/default.jpg'
            },
            relatedImages: {
                1: '../images/default.jpg',
                2: '../images/default.jpg',
                3: '../images/default.jpg'
            },
            default: '../images/default.jpg',

            width: widthWindow
        }

        var context = this;
        window.addEventListener('resize', function(event) {
            const widthWindow  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            context.updateWidth(widthWindow);
        }, true);
    }
    
    updateWidth = (width) => {
        if (width !== this.state.width) {
            this.setState({
                width: width
            });
        }
    }

	componentDidMount() {
        var search = this.props.location.pathname.replace("/item/", "");
        this.onLoadData(search);
    }

    // componentDidMount()
    // {
        
    // }

    onLoadData = (url) => {
        var request = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                url: url
            })
        };

        fetch(config.base_url + config.version + 'product/fetchProduct', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error == 0) {
                console.log("fetchProduct: ", data.data)
                let accessory = data.data.accessory;
                let product = data.data.product;
                let relatedProducts = data.data.relatedProducts;
                
                let type = 0;
                let code = 0;
                if (product != null && product != undefined) {
                    type = 1;
                    code = product.code;
                } else if (accessory != null && accessory != undefined) {
                    type = 2;
                    code = accessory.code;
                }
                
                let images = this.state.images;
                for(var position = 1; position <= 6; position++)
                {
                    let src =  `${config.GOOGLE_STORAGE}/${config.FIREBASE_STORAGE_BUCKET}/${type === 1 ? "products" : "accessories"}/${code}/${position}`                    
                    images[position] = src;
                }
                this.setState({ images: images });

                if (parseInt(type) === 1) {
                    this.setState({ item: product, stock: product.stock, loaded: true, relatedProducts: relatedProducts, code: code, type: type });
                } else if (parseInt(type) === 2){
                    this.setState({ item: accessory, stock: accessory.stock, loaded: true, relatedProducts: relatedProducts, code: code, type: type });
                }
                this.loadImageProductRelated(type, relatedProducts);

            } else {
                window.location.href = "/shop";
            }
        });
    }

    loadImageProductRelated = (type, relatedProducts) => {
        var imagesMap = this.state.relatedImages;
        relatedProducts.forEach( (item, index) => {
            let src =  `${config.GOOGLE_STORAGE}/${config.FIREBASE_STORAGE_BUCKET}/${type === 1 ? "products" : "accessories"}/${item.code}/thumbnail`                    
            imagesMap[index+1] = src;
        });
        this.setState({ relatedImages: imagesMap });
    }

    convertToMoney = (price) => {
        return Intl.NumberFormat("de-DE").format(price)
    }

    addQuantity = () => {
        var quantity = this.state.quantity;
        if (quantity < 3) {
            quantity += 1;
            this.setState({ quantity: quantity });
        }
    }

    removeQuantity = () => {
        var quantity = this.state.quantity;
        if (quantity > 1) {
            quantity -= 1;
            this.setState({ quantity: quantity });
        }
    }

    stringToHTML = (data) => {
        if (data !== undefined) {
            var doc = document.getElementById("containerDescription");
            doc.innerHTML = data            
        }
    }

    formatPriceWithoutDot = (price) => {
        console.log(price)
        var newPrice = price.toString().replace(".", "");
        return newPrice;
    }

    onAddCart = () => {
        var json = localStorage.getItem("cartItems")
        var list = []
        if (json !== undefined && json !== null) {
            list = JSON.parse(json);
        }
        
        var index = -1;
        for (var i=0; i<list.length; i++) {
            if (list[i].code === this.state.item.code) {
                index = i;
                list[i].quantity += this.state.quantity; 
            }
        }

        if (index === -1) {
            list.push({
                prefix: this.state.item.prefix,
                code: this.state.item.code,
                name: this.state.item.name,
                price: this.formatPriceWithoutDot(this.state.item.price),
                quantity: this.state.quantity,
                type: this.state.item.productType,
                discount: this.state.item.discount,
                promo3x2: this.state.item.promo3x2
            });
        }
        
        var dataJSON = JSON.stringify(list);
        localStorage.setItem("cartItems", dataJSON);

        NotificationManager.success('Estás un paso mas cerca de tu proxima partida 😎', 'Agregado al carrito');

        var data = this.state.changeDataCart + 1;
        this.setState({ changeDataCart: data });
    }


    getPriceRelatedProducts = (position) => {
        if (position < this.state.relatedProducts.length) {
            let originalPrice = this.state.relatedProducts[position].price;
            let discount = this.state.relatedProducts[position].discount;
            let oldPrice = ""
            if (discount !== 0) {
                oldPrice = <span style={{paddingLeft:"20px", fontSize: '18px'}}>${convertToMoney(this.state.relatedProducts[position].price)}</span>
                originalPrice = originalPrice * (1 - discount/100)
            }
            return(
                <div className="price_rp">
                    $ {convertToMoney(originalPrice)}
                    {oldPrice}<br/>
                </div>
            )
        } else {
            return '';
        }
    }

    getName = (position) => {
        if (position < this.state.relatedProducts.length) {
            return this.state.relatedProducts[position].name;
        } else {
            return '';
        }
    }

    getCode = (position) => {
        if (position < this.state.relatedProducts.length) {
            return this.state.relatedProducts[position].path;
        } else {
            return '';
        }
    }

    addCart = (position) => {
        var json = localStorage.getItem("cartItems")
        var list = []
        if (json !== undefined && json !== null) {
            list = JSON.parse(json);
        }
        
        var index = -1;
        for (var i=0; i<list.length; i++) {
            if (list[i].code === this.state.relatedProducts[position].code) {
                index = i;
                list[i].quantity += 1; 
            }
        }

        if (index === -1) {
                
            let shopItemsjson = sessionStorage.getItem("shopItems")
            let shopItemslist = []
            if (shopItemsjson !== undefined && shopItemsjson !== null) {
                shopItemslist = JSON.parse(shopItemsjson);
            }

            let isPromo3x2 = false
            shopItemslist.forEach(item => {
                if(item.code == this.state.relatedProducts[position].code)
                {
                    isPromo3x2 = true
                    return
                }
            })

            list.push({
                prefix: this.state.relatedProducts[position].prefix,
                code: this.state.relatedProducts[position].code,
                name: this.state.relatedProducts[position].name,
                price: this.formatPriceWithoutDot(this.state.relatedProducts[position].price),
                quantity: 1,
                discount: this.state.relatedProducts[position].discount,
                type: this.state.type,
                promo3x2: isPromo3x2
            });
        }

        var dataJSON = JSON.stringify(list);
        localStorage.setItem("cartItems", dataJSON);

        NotificationManager.success('Estás un paso mas cerca de tu proxima partida 😎', 'Agregado al carrito');

        var data = this.state.changeDataCart + 1;
        this.setState({ changeDataCart: data });
    }

    getPriceComponent = () => {
        let originalPrice = this.state.item.price;
        let discount = this.state.item.discount;
        let oldPrice, oldPrice2 = ""
        if (discount !== 0) {
            oldPrice = <span className="displayOnMobile">${convertToMoney(this.state.item.price)}</span>
            oldPrice2 = <span className="notDisplayOnMobile">${convertToMoney(this.state.item.price)}</span>
            originalPrice = originalPrice * (1 - discount/100)
        }
        return(
            <div className="price">
                {oldPrice}
                <div>${convertToMoney(originalPrice)}{oldPrice2}</div>            
            </div>
        )
    }

	render() {
        return (
            <div>
                <link rel="preconnect" href="https://enigmagames.uy"></link>
                <link rel="preconnect" href="https://firebase.googleapis.com"></link>
                <div style={{display: this.state.loaded?'block':'none'}}>
                    <HeaderTop dark={true} dataCart={this.state.changeDataCart}/>
                    <main className="cd-main-content" style={{width: this.state.width}}>
                        <section style={{paddingTop: "70px"}} className="section white-section section-home-padding-top">
                            <div className="container">
                                <div>
                                    <div className="section-title left">
                                        <h2 className="itemDetailTitle" style={{textAlign:'center'}} >{ this.state.item.name }</h2>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section white-section section-padding-bottom">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 imagesContainer">
                                        <div className="shop-carousel-wrap">
                                            <div id="sync1" className="owl-carousel">
                                                <div className="item" >
                                                    <img alt="Main" fetchpriority="high" src={this.state.images[1]} />
                                                </div>
                                                <div className="item" style={{display: (this.state.images[2]!==this.state.default)?'block':'none'}}>
                                                    <img alt="Secondary" src={this.state.images[2]} />
                                                </div>
                                                <div className="item" style={{display: (this.state.images[3]!==this.state.default)?'block':'none'}}>
                                                    <img alt="Alternative 1" src={this.state.images[3]} />
                                                </div>
                                                <div className="item" style={{display: (this.state.images[4]!==this.state.default)?'block':'none'}}>
                                                    <img alt="Alternative 2" src={this.state.images[4]} />
                                                </div>
                                                <div className="item" style={{display: (this.state.images[5]!==this.state.default)?'block':'none'}}>
                                                    <img alt="Alternative 3" src={this.state.images[5]} />
                                                </div>
                                                <div className="item" style={{display: (this.state.images[6]!==this.state.default)?'block':'none'}}>
                                                    <img alt="Alternative 4" src={this.state.images[6]} />
                                                </div>
                                            </div>
                                            <div id="sync2" className="owl-carousel">
                                                <div className="item">
                                                    <img alt="Main" src={this.state.images[1]} />
                                                </div>
                                                <div className="item" style={{display: (this.state.images[2]!==this.state.default)?'block':'none'}}>
                                                    <img alt="Secondary" src={this.state.images[2]} />
                                                </div>
                                                <div className="item" style={{display: (this.state.images[3]!==this.state.default)?'block':'none'}}>
                                                    <img alt="Alternative 1" src={this.state.images[3]} />
                                                </div>
                                                <div className="item" style={{display: (this.state.images[4]!==this.state.default)?'block':'none'}}>
                                                    <img alt="Alternative 2" src={this.state.images[4]} />
                                                </div>
                                                <div className="item" style={{display: (this.state.images[5]!==this.state.default)?'block':'none'}}>
                                                    <img alt="Alternative 3" src={this.state.images[5]} />
                                                </div>
                                                <div className="item" style={{display: (this.state.images[6]!==this.state.default)?'block':'none'}}>
                                                    <img alt="Alternative 4" src={this.state.images[6]} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="shop-item-details">
                                            {this.getPriceComponent()}
                                            <div style={{display: this.state.stock===0?'none':'block'}}>
                                                <div onClick={() => this.removeQuantity() } className={this.state.quantity==1?'num-itm-ic cart-item':'num-itm-ic'}>&#xf068;</div>
                                                <div className="num-itm">{ this.state.quantity }</div>
                                                <div onClick={() => this.addQuantity() } className={this.state.quantity==3?'num-itm-ic cart-item':'num-itm-ic'}>&#xf067;</div>                                            
                                                <a href="#/" onClick={() => this.onAddCart()} style={{cursor: 'pointer'}}><div className="button-shop">Agregar al carrito</div></a>
                                            </div>
                                            <div style={{display: this.state.stock===0?'block':'none'}}>
                                                <h3 style={{color: '#c5190c', textAlign: 'inherit'}}>Sin stock</h3>
                                            </div>
                                        </div>
                                        <div className="after-button">
                                            <div className= "divTable blueTable">
                                                <div className="divTableBody">
                                                    <div className="divTableRow rowOdd">
                                                        <div className="divTableCell divTableCellFirst">Jugadores</div>
                                                        <div className="divTableCell"> De { this.state.item.minPlayers } a { this.state.item.maxPlayers }</div>
                                                    </div>
                                                    <div className="divTableRow " >
                                                        <div className="divTableCell divTableCellFirst">Categoría</div>
                                                        <div className="divTableCell">{GAMES_CATEGORIES[this.state.item.category]}</div>
                                                    </div>
                                                    <div className="divTableRow rowOdd">
                                                        <div className="divTableCell divTableCellFirst">Dificultad</div>
                                                        <div className="divTableCell">{GAMES_DIFFICULTY[this.state.item.difficulty]}</div>
                                                    </div>
                                                    <div className="divTableRow">
                                                        <div className="divTableCell divTableCellFirst">Duración</div><div className="divTableCell">{this.state.item.gameDuration} minutos</div></div>
                                                    <div className="divTableRow rowOdd">
                                                        <div className="divTableCell divTableCellFirst">Es expansión</div>
                                                        <div className="divTableCell">{this.state.item.expansion == 1 ? "Si" : "No"}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="after-button">
                                            <div style={{paddingTop: '30px'}} id="containerDescription" className="containerDescriptionProd"> { this.stringToHTML(this.state.item.description) } </div>	
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section grey-section section-padding-top-bottom" style={{display: (this.state.relatedProducts.length === 0)?'none':'block'}}>
                            <div className="container">
                                <div className="section-title">
                                    <h3>Productos relacionados</h3>
                                </div>
                                <div className="row">
                                    <div className="col-md-4" style={{marginTop:'15px', display: (this.state.relatedProducts.length >= 1)?'block':'none'}}>
                                        <div className="shop-box grey-section">
                                            <img alt="Related product 1" src={this.state.relatedImages[1]} onError={(e) => {e.target.onerror = null; e.target.src='./images/default.jpg'}} />
                                            <div className="mask-shop-white">                                        
                                            <h4 className="shop-price" style={{fontSize: "20px", paddingLeft: "10px", paddingRight: "10px", lineHeight: "20px", letterSpacing: "3px"}}>{this.getName(0)}</h4>
                                            <div className="shop-price shop-price1">{this.getPriceRelatedProducts(0)}</div> 
                                            <a className="animaition-link" href={`/item/${this.getCode(0)}`}><div className="mask-left-shop">&#xf06e;</div></a>
                                            <a href="#/" onClick={() => this.addCart(0)} style={{cursor: 'pointer'}}><div className="mask-right-shop">&#xf07a;</div></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4" style={{marginTop:'15px', display: (this.state.relatedProducts.length >= 2)?'block':'none'}}>
                                        <div className="shop-box grey-section">
                                            <img  alt="Related product 2" src={this.state.relatedImages[2]} onError={(e) => {e.target.onerror = null; e.target.src='./images/default.jpg'}} />
                                            <div className="mask-shop-white">                                        
                                            <h4 className="shop-price" style={{fontSize: "20px", paddingLeft: "10px", paddingRight: "10px", lineHeight: "20px", letterSpacing: "3px"}}>{this.getName(1)}</h4>
                                            <div className="shop-price shop-price1">{this.getPriceRelatedProducts(1)}</div> 
                                            <a className="animaition-link" href={`/item/${this.getCode(1)}`}><div className="mask-left-shop">&#xf06e;</div></a>
                                            <a href="#/" onClick={() => this.addCart(1)} style={{cursor: 'pointer'}}><div className="mask-right-shop">&#xf07a;</div></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4" style={{marginTop:'15px', display: (this.state.relatedProducts.length >= 3)?'block':'none'}}>
                                        <div className="shop-box grey-section">
                                            <img  alt="Related product 3" src={this.state.relatedImages[3]} onError={(e) => {e.target.onerror = null; e.target.src='./images/default.jpg'}} />
                                            <div className="mask-shop-white">                                        
                                            <h4 className="shop-price" style={{fontSize: "20px", paddingLeft: "10px", paddingRight: "10px", lineHeight: "20px", letterSpacing: "3px"}}>{this.getName(2)}</h4>
                                            <div className="shop-price shop-price1">{this.getPriceRelatedProducts(2)}</div> 
                                            <a className="animaition-link" href={`/item/${this.getCode(2)}`}><div className="mask-left-shop">&#xf06e;</div></a>
                                            <a href="#/" onClick={() => this.addCart(2)} style={{cursor: 'pointer'}}><div className="mask-right-shop">&#xf07a;</div></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <SectionFooter />
                    </main>
                    <SectionScrollToUp />
                </div>
                <div className="loader" style={{display: this.state.loaded?'none':'block'}}></div>
            </div>
        );
    }
    
}

export default ShopItemPage;