import React, { Component } from 'react';

import HeaderTop from '../components/HeaderTop';
import SectionCart from '../components/SectionCart';
import SectionCartTop from '../components/SectionCartTop';
import SectionScrollToUp from '../components/SectionScrollToUp';
import SectionFooter from '../components/SectionFooter';

class CartPage extends Component {

	constructor(){
        super();
        
        const widthWindow = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.state = {
            changeDataCart: 0,
            width: widthWindow
        }
        
        var context = this;
        window.addEventListener('resize', function(event) {
            const widthWindow  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            context.updateWidth(widthWindow);
        }, true);
	}

    updateWidth = (width) => {
        if (width !== this.state.width) {
            this.setState({
                width: width
            });
        }
    }

    handleChangeItemCart = () => {
        var data = this.state.changeDataCart + 1;
        this.setState({
            changeDataCart: data
        });
    }

    getSectionCartComponent = () => {
        if (this.state.width < 992) {
            return <SectionCart onChangeCart={ this.handleChangeItemCart } isMobile={true} />
        } else {
            return <SectionCart onChangeCart={ this.handleChangeItemCart } isMobile={false}/>
        }
    }
    
	render() {
        return (  
            <div>
                <HeaderTop dark={true} dataCart={this.state.changeDataCart}/>
                <main className="cd-main-content" style={{width: this.state.width}}>
                    <SectionCartTop />
                    { this.getSectionCartComponent() }
                    <SectionFooter />
                </main>
                <SectionScrollToUp />
            </div>
        );
    }
    
}

export default CartPage;