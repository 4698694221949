import React, { Component } from 'react';

import HeaderTop from '../components/HeaderTop';
import SectionScrollToUp from '../components/SectionScrollToUp';
import SectionFooter from '../components/SectionFooter';
import {TICKET_PAYMENT_METHOD} from '../utils/enums';
import config from '../config.json';
import { NotificationManager } from 'react-notifications';
import {convertToMoney, validateCI} from  '../utils/utils';
import { loadMercadoPago } from "@mercadopago/sdk-js";


class CheckoutPage extends Component {

	constructor(){
        super();
        this.state = {
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            city: "",
            department: "",
            address: "",
            cedula: "",
            hourPreference: "",
            comments: "",
            paymentType: 3,
            promoCode: "",
            shippingType: "2",
            useOtherDataForShipping: false,
            shippingDepartment: "",
            shippingCity: "",
            shippingAddress: "",
            shippingPostalCode: "",
            appliedDiscount: 0,
            shippingComments: "",
            shippingHourPreference: "",
            loading: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
    }
    
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.handleChange(name, value);
    }

    handleInputChangeRetiro = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({ 
            shippingAddress: "",
            shippingCity: "",
            shippingComments: "",
            shippingDepartment: "",
            shippingHourPreference: "",
            shippingPostalCode: "",
            useOtherDataForShipping: false
        });
        this.handleChange(name, value);
    }

    handleSelectChange(id) {
        var value = document.getElementById(id).value;
        this.handleChange(id, value);
    }

    handleCheckChange(event) {
        const name = event.target.name;
        const isChecked = event.target.checked;
        this.handleChange(name, isChecked);
    }
      
    handleChange = (name, value) => {
        this.setState({ [name]: value })
        localStorage.setItem("checkout_state", JSON.stringify(this.state))
    }

    async componentDidMount() {
        try {            
            await loadMercadoPago()            
            const mp = new window.MercadoPago('TEST-e41c679b-d9a1-44e2-a75f-83f5a107af89', {
                locale: "en-US",
            });
            window.MercadoPago = mp;

            const state = localStorage.getItem('checkout_state');
            this.setState({ ...JSON.parse(state) });
            this.setState({
                "paymentType": TICKET_PAYMENT_METHOD.Mercado_Pago,
                "appliedDiscount": 0,
                "promoCode": ""
            });
            let jsonData = JSON.parse(state);
            let department = jsonData.department;
            let shippingDepartment = jsonData.shippingDepartment;
            if (department != null && department != undefined && department != "") {
                document.getElementById("department").value = department;
            }
            if (shippingDepartment != null && shippingDepartment != undefined && shippingDepartment != "") {
                document.getElementById("shippingDepartment").value = shippingDepartment;
            }
        } catch (e) {}
    }

    validateInput = (id, forceError) => {
        let input = document.getElementById(id);
        if (input.value === undefined || input.value === null || input.value === "" || forceError) {
            input.classList.add("type-error");
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return false;
        } else {
            input.classList.remove("type-error");
            return true;
        }
    }

    validateEmail = (email) => {
        var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return email.search(re) === 0;
    }

    isValidData = () => {
        let result = true;
        result &= this.validateInput("firstName", false);
        result &= this.validateInput("lastName", false);
        result &= this.validateInput("phone", false);
        result &= this.validateInput("cedula", false);
        result &= this.validateInput("email", false);
        result &= this.validateInput("department", false);
        result &= this.validateInput("city", false);
        result &= this.validateInput("address", false);
        if (this.state.useOtherDataForShipping) {
            result &= this.validateInput("shippingDepartment", false);
            result &= this.validateInput("shippingCity", false);
            result &= this.validateInput("shippingAddress", false);
            result &= this.validateInput("shippingPostalCode", false);
            result &= this.validateInput("address", false);
            result &= this.validateInput("address", false);
        }
        // Validate format for email and ci
        let isValidEmail = this.validateEmail(this.state.email);
        if (!isValidEmail){
            result &= this.validateInput("email", true);
        }
        let isValidCI = validateCI(this.state.cedula);
        if (!isValidCI) {
            result &= this.validateInput("cedula", true);
        }
        return result;
    }

    purchaseAndPay = () => {
        if (!this.isValidData()) {
            return;
        }
        localStorage.setItem("checkout_state", JSON.stringify(this.state))
        
        this.setState({"loading": true});
        let cartProducts = JSON.parse(localStorage.getItem("cartItems"));
        let products = []
        cartProducts.forEach(cartProduct => {
            products.push({
                prefix: cartProduct.prefix,
                code: parseInt(cartProduct.code),
                quantity: parseInt(cartProduct.quantity),
                productType: parseInt(cartProduct.type)
            });
        })

        //ACA va la cosa
        let shippingDepartment = this.state.shippingDepartment;
        let shippingCity = this.state.shippingCity;
        let shippingAddress = this.state.shippingAddress;
        let shippingPostalCode = this.state.shippingPostalCode;
        if (!this.state.useOtherDataForShipping) {
            shippingDepartment = this.state.department;
            shippingCity = this.state.city;
            shippingAddress = this.state.address;
        } 

        var request = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                cedula: this.state.cedula,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phone: this.state.phone,
                department: this.state.department,
                city: this.state.city,
                address: this.state.address,
                paymentType: parseInt(this.state.paymentType),
                discountCode: this.state.promoCode,
                products: products,                
                shippingType: parseInt(this.state.shippingType),
                shippingDepartment: shippingDepartment,
                shippingCity: shippingCity,
                shippingAddress: shippingAddress,
                shippingPostalCode: shippingPostalCode,
                shippingHourPreference: this.state.shippingHourPreference,
                shippingComments: this.state.shippingComments
            })
        };

        const mp = window.MercadoPago;
        fetch(config.base_url + config.version + 'purchase/new', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                this.setState({"loading": false});
                NotificationManager.error(data.message, 'No hay stock!');
                sessionStorage.removeItem("shopItems")
            } else {
                if (this.state.paymentType == TICKET_PAYMENT_METHOD.Mercado_Pago) {                    
                    this.setState({"loading": false})
                    window.checkout = mp.checkout({
                        preference: {
                            id: data.data.id,                        
                        },
                        autoOpen: true
                    })
                } else {
                    window.location.href =  '/result?result=success'
                }
            }
        });
    }

    getCartItems = () => {
        var json = localStorage.getItem("cartItems")
        var items = []
        if (json !== undefined && json !== null) {
            items = JSON.parse(json);
            let subtotal = 0
            let shippingPrice = 0
            const listItems = items.map((item, index) => {
                let realPrice = parseInt(item.price)*item.quantity;
                let discount = item.discount;
                if (discount !== 0)
                    realPrice = (realPrice * (1 - discount/100))
                
                subtotal += realPrice
                return (<h6 className = "resumeList"><a className='checkoutCartItem'>({item.quantity})</a> <a className='checkoutCartItem checkoutCartItemName'>{item.name}</a><a className='checkoutCartItem checkoutCartPrice'>$ {convertToMoney(realPrice)}</a></h6>)
            })
            if (this.state.shippingType == 1 && subtotal < 3000)
                shippingPrice = 200
            
            let discount = this.calculateDiscounts(items)
            let finalDiscount = 0
            if (this.state.appliedDiscount > 0)
                finalDiscount = this.state.appliedDiscount
            else if  (discount > 0)
                finalDiscount =  discount
            let total = subtotal - finalDiscount + shippingPrice
            return(
            <div style={{float: "left"}} className="cart-totals">
                <h5 style={{textAlign: "center", marginTop: "0px"}}>Resumen del pedido</h5>
                {listItems}
                <h6 style={{borderTop: "2px solid",paddingTop: "4px"}}>Subtotal<span className='checkoutCartItem checkoutCartPrice'>$ {convertToMoney(subtotal)}</span></h6>
                <h6 style={{borderBottom: "0px", marginTop: "-11px",paddingBottom: "0px"}}>Envío<span className='checkoutCartItem checkoutCartPrice'>$ {convertToMoney(shippingPrice)}</span></h6>
                {this.renderDiscount(discount)}
                <h5 style={{marginBottom: "0px", borderBottom: "0px",borderTop: "2px solid", marginTop: "-11px" ,paddingTop: "10px"}}>Total del pedido <span  className='checkoutCartItem checkoutCartPrice'>$ {convertToMoney(total)}</span></h5>
            </div>)
        }
    }

    calculateDiscounts = (items) => {
        let discountAmount = 0
        let validProducts = [];
        items.forEach(item => {
            if (item.promo3x2)
            {
                for (let i=0; i<item.quantity; i++) {
                    validProducts.push({
                        productCode: item.productCode,
                        price: item.price * (1 - item.discount / 100)
                    });
                }
            }            
        });
        //Ordenamos la lista por precio
        validProducts.sort(this.compararPrecios);

        let itemsToRemove = Math.trunc(validProducts.length / 3);

        for (let i=0; i<itemsToRemove; i++) {
            discountAmount += validProducts[i].price;
        }
        return discountAmount
    }

    compararPrecios = (a, b) => {
        if (a.price > b.price) {
            return 1; // Si a tiene un precio mayor, a debe aparecer después en la lista
        } else if (a.price < b.price) {
            return -1; // Si a tiene un precio menor, a debe aparecer antes en la lista
        } else {
            return 0; // Si tienen el mismo precio, el orden no importa
        }
    }

    renderDiscount = (discount) => {
        let finalDiscount = 0
        if (this.state.appliedDiscount > 0)
            finalDiscount = this.state.appliedDiscount
        else if  (discount > 0)
            finalDiscount =  discount
        
        if (finalDiscount > 0)
        {
            return (<h6 style={{borderTop: "1px solid", marginTop: "-11px", borderBottom: "0px", paddingTop: "4px"}}>Descuento<span className='checkoutCartItem checkoutCartPrice'>-$ {convertToMoney(finalDiscount)}</span></h6>)
        }
    }

    renderPaymentTypes = () => {}

    renderSpecificShippingData = () => {
        if (this.state.useOtherDataForShipping)
            return (
                <div className="col-md-12">
                    <div className= "row">
                        <div className="col-md-6" style={{marginTop: '15px'}}>
                            <label htmlFor="shippingDepartment"> 
                                <span className="error" id="err-shippingDepartment">Ingrese el departamento</span>
                            </label>
                            <select name="shippingDepartment" id="shippingDepartment" className="custom-select-checkout" onChange={() => this.handleSelectChange("shippingDepartment")}>
                                <option value="" selected disabled hidden>Seleccione un departamento</option>
                                <option value="Artigas">Artigas</option>
                                <option value="Canelones">Canelones</option>
                                <option value="Cerro Largo">Cerro Largo</option>
                                <option value="Colonia">Colonia</option>
                                <option value="Durazno">Durazno</option>
                                <option value="Flores">Flores</option>
                                <option value="Florida">Florida</option>
                                <option value="Lavalleja">Lavalleja</option>
                                <option value="Maldonado">Maldonado</option>
                                <option value="Montevideo">Montevideo</option>
                                <option value="Paysandú">Paysandú</option>
                                <option value="Río Negro">Río Negro</option>
                                <option value="Rivera">Rivera</option>
                                <option value="Rocha">Rocha</option>
                                <option value="Salto">Salto</option>
                                <option value="San José">San José</option>
                                <option value="Soriano">Soriano</option>
                                <option value="Tacuarembó">Tacuarembó</option>
                                <option value="Treinta y Tres">Treinta y Tres</option>
                            </select>
                        </div>
                        <div className="col-md-6" style={{marginTop: '15px'}}>
                            <label htmlFor="shippingCity"> 
                                <span className="error" id="err-shippingCity">Ingrese la ciudad</span>
                            </label>
                            <input className="input-form" name="shippingCity" id="shippingCity" type="text" placeholder="Ciudad: *" value={this.state.shippingCity} onChange={this.handleInputChange}/>
                        </div>
                    </div>
                    <div className= "row">
                        <div className="col-md-12" style={{marginTop: '15px'}}>
                            <label htmlFor="shippingAddress"> 
                                <span className="error" id="err-shippingAddress">Ingrese la dirección</span>
                            </label>
                            <input className="input-form" name="shippingAddress" id="shippingAddress" type="text" placeholder="Dirección: *" value={this.state.shippingAddress} onChange={this.handleInputChange}/>
                        </div>
                        <div className="col-md-12" style={{marginTop: '15px'}}>
                            <label htmlFor="shippingPostalCode"> 
                                <span className="error" id="err-shippingPostalCode">Ingrese el código postal</span>
                            </label>
                            <input className="input-form" name="shippingPostalCode" id="shippingPostalCode" type="number" placeholder="Código postal: *" value={this.state.shippingPostalCode} onChange={this.handleInputChange}/>
                        </div>
                    </div>
                </div>
            )
    }

    renderShippingTypes = () => {
        var tablaEnvio = <div className="row"> 
                            <div className="row same-data-class">                                 
                                <div className="" style={{alignSelf:'center', marginLeft:'15px'}}>
                                    <input type="checkbox" name='useOtherDataForShipping' id="useOtherDataForShipping" className="input-shop check-data-shipping" 
                                            onChange={this.handleCheckChange} defaultChecked={this.state.useOtherDataForShipping}/>
                                </div>
                                <div className="col-10" style={{fontSize: '13px'}}>
                                    <label htmlFor="useOtherDataForShipping"> 
                                        <span>Enviar a una dirección diferente</span>
                                    </label>                                    
                                </div>
                            </div>
                            {this.renderSpecificShippingData()}
                            <div className="col-md-12" style={{marginTop: '15px'}}>
                                <label htmlFor="shippingHourPreference"> 
                                    <span className="error" id="err-shippingHourPreference">Ingrese el horario de preferencia</span>
                                </label>
                                <input className="input-form" name="shippingHourPreference" id="shippingHourPreference" type="text" placeholder="Horario de preferencia" value={this.state.shippingHourPreference} onChange={this.handleInputChange}/>
                            </div>
                            <div className="col-md-12" style={{marginTop: '15px'}}>
                                <label htmlFor="shippingComments"> 
                                    <span className="error" id="err-shippingComments">Ingrese comentarios</span>
                                </label>
                                <input className="input-form" name="shippingComments" id="shippingComments" type="text" placeholder="Comentarios" value={this.state.shippingComments} onChange={this.handleInputChange}/>
                            </div>
                        </div>
        var tablaRetiro = <label>Te contactaremos a la brevedad para coordinar el retiro!</label>

        if (this.state.shippingType == "2")
            return tablaRetiro
        else
            return tablaEnvio
    }

    validatePromoCode = () => {
        var json = localStorage.getItem("cartItems")
        var items = []
        if (json !== undefined && json !== null) {
            items = JSON.parse(json);        
            var request = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    code: this.state.promoCode,
                    products: items
                })
            };
            fetch(config.base_url + config.version + 'purchase/applyCodeAndGetPrice', request)
            .then(results => { return results.json(); })
            .then(data => {
                if (data.error > 0) {
                    alert(data.message);
                } else {
                    this.setState({ appliedDiscount: data.data.price.discount});
                    if (this.state.appliedDiscount === 0) {
                        this.setState({ promoCode: ""});
                        NotificationManager.error('El código de promoción ingresado no es valido.', 'Código inválido');
                    }else
                        NotificationManager.success('Código promocional fue validado con éxito. El descuento aplicado se muestra en el resumen del pedido.', 'Código exitoso');         
                }
            })
        }
    }
    
    renderFactracionBoxData = () => {
        return(
            <div className="accordion">
                <div className="accordion_in acc_active" data-scroll-reveal="enter right move 200px over 1s after 0.3s">
                    <div className="acc_head white-section" style={{padding: '10px 20px', fontSize: "15px" }}>Datos de facturación</div>
                        <div name="ajax-form" id="ajax-form" className="acc_content white-section">
                        <div className="row" style={{marginBottom: '0px'}}>
                            <div name="ajax-form" id="ajax-form">
                                <div className="row">
                                    <div className="col-md-6" style={{marginTop: '15px'}}>
                                        <label htmlFor="firstName"> 
                                            <span className="error" id="err-firstName">Ingrese el nombre</span>
                                        </label>
                                        <input className="input-form" name="firstName" id="firstName" type="text" placeholder="Nombre: *" value={this.state.firstName} onChange={this.handleInputChange}/>
                                    </div>
                                    <div className="col-md-6" style={{marginTop: '15px'}}>
                                        <label htmlFor="lastname"> 
                                            <span className="error" id="err-lastname">Ingrese el apellido</span>
                                        </label>
                                        <input className="input-form" name="lastName" id="lastName" type="text" placeholder="Apellido: *" value={this.state.lastName} onChange={this.handleInputChange}/>
                                    </div>
                                    <div className="col-md-6" style={{marginTop: '15px'}}>
                                        <label htmlFor="phone"> 
                                            <span className="error" id="err-phone">Ingrese el teléfono</span>
                                        </label>
                                        <input className="input-form" name="phone" id="phone" type="number" placeholder="Teléfono: *" value={this.state.phone} onChange={this.handleInputChange}/>
                                    </div>
                                    <div className="col-md-6" style={{marginTop: '15px'}}>
                                        <label htmlFor="cedula"> 
                                            <span className="error" id="err-email">Ingrese su cedula</span>
                                        </label>
                                        <input className="input-form" name="cedula" id="cedula" type="number" placeholder="cedula: *" value={this.state.cedula} onChange={this.handleInputChange}/>
                                    </div>                                    
                                    <div className="col-md-12" style={{marginTop: '15px'}}>
                                        <label htmlFor="email"> 
                                            <span className="error" id="err-email">Ingrese el correo</span>
                                        </label>
                                        <input className="input-form" name="email" id="email" type="text" placeholder="Correo: *" value={this.state.email} onChange={this.handleInputChange}/>
                                    </div>
                                    <div className="col-md-6" style={{marginTop: '15px'}}>
                                        <label htmlFor="department"> 
                                            <span className="error" id="err-department">Ingrese el departamento</span>
                                        </label>
                                        <select name="department" id="department" className="custom-select-checkout" onChange={() => this.handleSelectChange("department")}>
                                            <option value="" selected disabled hidden>Seleccione un departamento</option>
                                            <option value="Artigas">Artigas</option>
                                            <option value="Canelones">Canelones</option>
                                            <option value="Cerro Largo">Cerro Largo</option>
                                            <option value="Colonia">Colonia</option>
                                            <option value="Durazno">Durazno</option>
                                            <option value="Flores">Flores</option>
                                            <option value="Florida">Florida</option>
                                            <option value="Lavalleja">Lavalleja</option>
                                            <option value="Maldonado">Maldonado</option>
                                            <option value="Montevideo">Montevideo</option>
                                            <option value="Paysandú">Paysandú</option>
                                            <option value="Río Negro">Río Negro</option>
                                            <option value="Rivera">Rivera</option>
                                            <option value="Rocha">Rocha</option>
                                            <option value="Salto">Salto</option>
                                            <option value="San José">San José</option>
                                            <option value="Soriano">Soriano</option>
                                            <option value="Tacuarembó">Tacuarembó</option>
                                            <option value="Treinta y Tres">Treinta y Tres</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6" style={{marginTop: '15px'}}>
                                        <label htmlFor="city"> 
                                            <span className="error" id="err-city">Ingrese la ciudad</span>
                                        </label>
                                        <input className="input-form" name="city" id="city" type="text" placeholder="Ciudad: *" value={this.state.city} onChange={this.handleInputChange}/>
                                    </div>
                                    <div className="col-md-12" style={{marginTop: '15px'}}>
                                        <label htmlFor="address"> 
                                            <span className="error" id="err-address">Ingrese la dirección</span>
                                        </label>
                                        <input className="input-form" name="address" id="address" type="text" placeholder="Dirección: *" value={this.state.address} onChange={this.handleInputChange}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderShippingBoxData = () => {
        return(
            <div className="accordion">
                <div className="accordion_in acc_active" data-scroll-reveal="enter right move 200px over 1s after 0.3s">
                    <div className="acc_head white-section" style={{padding: '10px 20px', fontSize: "15px" }}>Datos de envío</div>
                        <div name="ajax-form" id="ajax-form" className="acc_content white-section">
                        <div className="row" style={{marginBottom: '0px'}}>
                            <div name="ajax-form" id="ajax-form">
                            <div className="row">
                                    <fieldset>
                                        <div className="col-xs-12">
                                            <legend >Elige la forma en que quieres recibir tu juego:</legend>
                                        </div>
                                        <div className="toggle col-xs-12">
                                            <input type="radio" name="shippingType" value={"2"} id="shippingTypeRetiro" onChange={this.handleInputChangeRetiro} checked={this.state.shippingType == "2"} />
                                            <label htmlFor="shippingTypeRetiro">Retiro</label>
                                            <input type="radio" name="shippingType" value={"1"} id="shippingTypeEnvio" onChange={this.handleInputChange} checked={this.state.shippingType == "1"}/>
                                            <label htmlFor="shippingTypeEnvio">Envio</label>
                                        </div>
                                    </fieldset>
                                </div>
                                {this.renderShippingTypes()}                                                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderPaymentBoxData = () => {
        return(
            <div className="accordion">
                <div className="accordion_in acc_active" data-scroll-reveal="enter right move 200px over 1s after 0.3s">
                    <div className="acc_head white-section" style={{padding: '10px 20px', fontSize: "15px" }}>Datos de pago</div>
                        <div name="ajax-form" id="ajax-form" className="acc_content white-section">
                        <div className="row" style={{marginBottom: '0px'}}>
                            <div name="ajax-form" id="ajax-form">
                                
                                <div className="row">
                                    {this.renderPaymentTypes()}
                                    <fieldset>
                                        <div className="col-xs-12">
                                            <legend>Método de pago:</legend>
                                        </div>
                                        <div className="toggle col-xs-12">
                                            <input type="radio" name="paymentType" value={TICKET_PAYMENT_METHOD.Mercado_Pago} id="paymentTypeMP" onChange={this.handleInputChange} checked={this.state.paymentType == TICKET_PAYMENT_METHOD.Mercado_Pago} />
                                            <label htmlFor="paymentTypeMP">Mercado Pago</label>
                                            <input type="radio" name="paymentType" value={TICKET_PAYMENT_METHOD.Efectivo} id="paymentTypeEfectivo" onChange={this.handleInputChange} checked={this.state.paymentType == TICKET_PAYMENT_METHOD.Efectivo}/>
                                            <label htmlFor="paymentTypeEfectivo">Efectivo</label>
                                        </div>
                                    </fieldset>
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row" style={{marginBottom: '0px'}}>
                                            <div className="col-xs-8">
                                                <input className="input-form" name="promoCode" id="promoCode" type="text" autoComplete="off" placeholder="Vale Promocional" onChange={this.handleInputChange} value={this.state.promoCode}/>
                                            </div>
                                            <div className="col-xs-4">
                                                <div id="validate-button" onClick={() => this.validatePromoCode()} 
                                                    className="button-shortcodes text-size-1 text-padding-1 version-1" 
                                                    style={{padding:"10px", marginRight: '-15px', float: 'left', width: '100%', textAlign: 'center'}}>
                                                    Validar
                                                </div>
                                            </div>
                                        </div>                                                                                                                 
                                    </div>                                   
                                </div> 
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        )
    }

    renderLoading = () => {
        return(
            <div className="EnigmaMask" style={this.state.loading ? {display:"block"} : {display:"none"}}>
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>    
            </div>
        )
    }

	render() {
        return (  
            <div>
                {this.renderLoading()}
                <HeaderTop dark={true} />
                <main className="cd-main-content" >
                    <section className="section white-section section-home-padding-top">
                        <div className="container">
                            <div className="sixteen columns">
                                <div className="section-title left">
                                    <h2>Finalizar compra</h2>
                                </div>
                            </div>
                        </div>    
                    </section>

                    <section className="section grey-section section-padding-top-bottom" style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">  
                                    <div className="row">
                                        <div className="col-md-12" style={{width: '100%', marginTop: "15px"}}>
                                            { this.renderFactracionBoxData()}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">  
                                    <div className="row">  
                                        <div className="col-md-12" style={{width: '100%', marginTop: "0px"}}>
                                            { this.renderShippingBoxData() }
                                        </div>
                                    </div>                                 
                                </div>
                                <div className="col-md-6">
                                    <div className="row"> 
                                        <div className="col-md-12" style={{width: '100%', marginTop: "0px"}}>
                                            { this.renderPaymentBoxData() }                                            
                                        </div>
                                    </div>                                    
                                </div>
                                <div className="col-md-12">
                                    <div style={{width: '100%', display: 'inline-flex'}}>    
                                    {this.getCartItems()}
                                    </div> 
                                </div>        
                                <div className="col-md-12">        
                                <div style={{width: '100%', display: 'inline-flex', paddingTop: '30px'}}>
                                    <div id="finish-button" onClick={() => this.purchaseAndPay()} 
                                        className="button-shortcodes text-size-1 text-padding-1 version-1" 
                                        style={{margin: 'auto',  width:'200px', display: 'block'}}>
                                        Realizar Pedido
                                    </div>   
                                </div>                                   
                            </div>                          
                            </div>             
                           
                        </div>
                    </section>   
                    <SectionFooter />
                </main>
                <SectionScrollToUp />
            </div>
        );
    }
    
}

export default CheckoutPage;