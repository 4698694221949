function convertToMoney(price) {
    price = Math.round(price)
    return Intl.NumberFormat('es-UY').format(price)
}

function validationDigit(ci) {
    var a = 0;
    var i = 0;
    if(ci.length <= 6){
        for(i = ci.length; i < 7; i++){
            ci = '0' + ci;
        }
    }
    for(i = 0; i < 7; i++){
        a += (parseInt("2987634"[i]) * parseInt(ci[i])) % 10;
    }
    if(a%10 === 0){
        return 0;
    }else{
        return 10 - a % 10;
    }
}
  
function validateCI(ci){
    var dig = ci[ci.length - 1];
    if (ci.length < 7 || ci.length > 8) {
        return false;
    } else {
        return (dig == validationDigit(ci));
    }
}

module.exports = {
    convertToMoney,
    validateCI
}