import React, { Component } from 'react';

class HeaderSlide extends Component {

    scrollToSection = () => {
        var element = document.getElementById("scroll-button")
        var rect = element.getBoundingClientRect();
        var currentPosition = document.documentElement.scrollTop;
        if (currentPosition == 0) {
            currentPosition = document.body.scrollTop;
        }
        currentPosition += rect.top + 70;
        
        window.scrollTo({
            top: currentPosition,
            left: 0,
            behavior: 'smooth'
        });
    }

    render() {
        return (
            <section className="home">
                 <a href="/shop" className="btn-tienda">VER TIENDA</a>
        
                <div className="slider-container">
                    <div className="tp-banner-container">
                        <div className="tp-banner" >
                            <ul>
                                <li data-transition="fade" data-slotamount="1" data-masterspeed="500"  data-saveperformance="on"  data-title="Intro Slide">
                                    <div className="portada portadaImage" />
                                    {/* <div className="tp-caption color-small randomrotate tp-resizeme"
                                        data-speed="200"
                                        data-start="300"
                                        data-easing="Power3.easeInOut"
                                        data-splitin="chars"
                                        data-splitout="none"
                                        data-elementdelay="0.1"
                                        data-endelementdelay="0.1"
                                        style={{zIndex: '500', maxWidth: 'auto', maxHeight: 'auto', whiteSpace: 'nowrap'}}>
                                        YA SABES?
                                    </div> */}
                                </li>

                                {/* <li data-transition="fade" data-slotamount="1" data-masterspeed="500"  data-saveperformance="on"  data-title="Intro Slide">
                                    <img src="images/image-5.png"  alt="slidebg2" data-lazyload="images/image-5.png" data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat" />
                                    <div className="tp-caption black-heavy randomrotate tp-resizeme"
                                        data-speed="200"
                                        data-start="300"
                                        data-easing="Power3.easeInOut"
                                        data-elementdelay="0.1"
                                        data-endelementdelay="0.1"
                                        style={{zIndex: '500', maxWidth: 'auto', maxHeight: 'auto', whiteSpace: 'nowrap'}}>
                                        <span className="border-text">Que pasa wachin</span>
                                    </div>
                                    <div className="tp-caption color-small randomrotate tp-resizeme"
                                        data-speed="200"
                                        data-start="300"
                                        data-easing="Power3.easeInOut"
                                        data-splitin="chars"
                                        data-splitout="none"
                                        data-elementdelay="0.1"
                                        data-endelementdelay="0.1"
                                        style={{zIndex: '500', maxWidth: 'auto', maxHeight: 'auto', whiteSpace: 'nowrap'}}>
                                        We Love What We Do
                                    </div>
                                </li>

                                <li data-transition="fade" data-slotamount="1" data-masterspeed="500"  data-saveperformance="on"  data-title="Intro Slide">
                                    <img src="images/image-3.png"  alt="slidebg3" data-lazyload="images/image-3.png" data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat" />
                                    <div className="tp-caption black-heavy randomrotate tp-resizeme"
                                        data-speed="200"
                                        data-start="300"
                                        data-easing="Power3.easeInOut"
                                        data-elementdelay="0.1"
                                        data-endelementdelay="0.1"
                                        style={{zIndex: '500', maxWidth: 'auto', maxHeight: 'auto', whiteSpace: 'nowrap'}}>
                                        <span className="border-text">Inspired By Purpose</span>
                                    </div>
                                    <div className="tp-caption color-small randomrotate tp-resizeme"
                                        data-speed="200"
                                        data-start="300"
                                        data-easing="Power3.easeInOut"
                                        data-splitin="chars"
                                        data-splitout="none"
                                        data-elementdelay="0.1"
                                        data-endelementdelay="0.1"
                                        style={{zIndex: '500', maxWidth: 'auto', maxHeight: 'auto', whiteSpace: 'nowrap'}}>
                                        Driven By Passion
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HeaderSlide;