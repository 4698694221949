import React, { Component } from 'react';
import config from '../config.json';

class ContactForm extends Component {

    validateInput = (input) => {
        if (input.vale === "" || input.vale === undefined) {
            input.className = "contact-error";
            return true;
        } else {
            input.className = "input-form";
            return false;
        }
    }

    SendEmail = () => {
        let name = document.getElementById("name").value;
        let email = document.getElementById("email").value;
        let message = document.getElementById("message").value;
        
        if (name !== undefined && name !== '' &&
            email !== undefined && email !== '' &&
            message !== undefined && message !== '') {
            var request = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    message: message
                })
            };
            fetch(config.base_url + config.version + 'user/contact', request)
            .then(results => { return results.json(); })
            .then(data => {
                console.log(data);
                if (data.error > 0) {
                    alert("Error: ", data.message);
                } else {
                    document.getElementById("container-form").style.display = 'none';
                    document.getElementById("container-message").style.display = 'block';
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            });
        }        
    }

    render() {
        return (
            <section className="section white-section section-padding-top-bottom">
                <div id="container-form" className="container" style={{paddingTop: '130px', paddingBottom: '50px'}}>
                    <div className="sixteen columns">
                        <div className="section-title">
                            <h3>Dejanos tu mensaje</h3>
                        </div>
                    </div>

                    <div className="clear"></div>

                    <div name="ajax-form" id="ajax-form">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="name"> 
                                        <span className="error" id="err-name">Ingrese el nombre</span>
                                    </label>
                                    <input className="input-form" name="name" id="name" type="text" placeholder="Nombre: *"/>
                                </div>
                                <div class="col-md-6">
                                    <label for="email"> 
                                        <span className="error" id="err-email">Ingrese el correo</span>
                                        <span className="error" id="err-emailvld">El correo no es un formato válido</span>
                                    </label>
                                    <input name="email" id="email" type="text"  placeholder="Correo: *"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label for="message"></label>
                                    <textarea name="message" id="message" placeholder="Mensaje: *" style={{textTransform: "none"}}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="sixteen columns" style={{width: '100%'}}>
                            <div id="button-con"><button onClick={() => this.SendEmail()} className="send_message">enviar</button></div>
                        </div>
                        <div className="clear"></div>	
                        <div className="error text-align-center" id="error-form">HUBO UN PROBLEMA VALIDANDO EL FORMULARIO POR FAVOR COMPRUEBE!</div>
                        <div className="error" id="err-state"></div>
                    </div>
                    
                    <div className="clear"></div>
                    
                    <div id="ajaxsuccess">Successfully sent!!</div>	
                    
                </div>
                
                <div id="container-message" className="container" style={{display: 'none', textAlign: 'center'}}>
                    <img src="/images/contact-message.png" width='400px' height='auto' style={{marginTop: '100px'}}/>
                </div>

                <div className="clear"></div>

                <div id="Map" className="container" style={{textAlign: 'center'}}>
                    <h3 className= "section-title">Visitanos en nuestro nuevo Local</h3>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1635.8738021850284!2d-56.15501960953612!3d-34.912782956729636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f81cfae5d29e9%3A0xd798907bbf26ef05!2sEnigma%20Games!5e0!3m2!1sen!2suy!4v1691789775357!5m2!1sen!2suy" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>

                </section>

            
        );
    }
}

export default ContactForm;