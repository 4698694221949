import React, { Component } from 'react';

class SectionFooter extends Component {
    render() {
        return (
            <div>
                <section style={{paddingTop: "45px", paddingBottom: "20px"}} className="section footer-1 section-padding-top-bottom">	
                    <div className="container">
                        <div className="row">                            
                            <div className="col-md-4" style={{display: 'flex', justifyContent: 'center'}} data-scroll-reveal="enter right move 200px over 0.5s after 0.3s">
                                <p style={{textTransform: "none"}} ><i className="icon-footer">&#xf041;</i>
                                Visitanos en <a rel="noopener noreferrer" style={{textTransform: "none"}} target="_blank" href="https://goo.gl/maps/MpvT1nfRh7JmbD9EA">José Ellauri 969, Montevideo, Uruguay</a>
                                <div>Lunes a Viernes de 14:00 a 20:00 horas.</div><div>Sábados de 11:00 a 17:00 horas.</div></p>
                            </div>
                            <div className="col-md-3" style={{display: 'flex', justifyContent: 'center'}} data-scroll-reveal="enter right move 200px over 0.5s after 0.3s">
                                <p ><i className="col-md-3 icon-footer">&#xf0e0;</i><a  style={{textTransform: "none"}} href="mailto:info@enigmagames.uy" title="">info@enigmagames.uy</a></p>
                            </div>                            
                            <div className="col-md-2" style={{display: 'flex', justifyContent: 'center'}} data-scroll-reveal="enter right move 200px over 0.5s after 0.3s">
                                <p><i style={{fontSize:'18px', color: '#1ec4a5'}} className="fa fa-whatsapp" /><a rel="noopener noreferrer" target="_blank" href="https://wa.me/59899089892" title="">099 089 892</a></p>
                            </div>
                            <div className="col-md-3" data-scroll-reveal="enter right move 200px over 0.5s after 0.3s">
                                <div className="social-bottom" style={{display: 'flex', justifyContent: 'center'}}>
                                    <ul className="list-social">
                                        <li className="icon-soc">
                                            <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/enigmagamesuy">&#xf16d;</a>
                                        </li>
                                        <li className="icon-soc">
                                            <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/Enigma-Games-106269838266623">&#xf09a;</a>
                                        </li>
                                    </ul>	
                                </div>
                            </div>
                        </div>                        
                    </div>
                </section>

                <section className="section footer-bottom">	
                    <div className="container">
                        <div><p>Los mejores juegos de mesa en Uruguay.</p></div>	
                        <div><p>© TODOS LOS DERECHOS RESERVADOS. REALIZADO POR ENIGMA GAMES - 2024</p></div>	
                    </div>
                </section>
            </div>
        );
    }
}

export default SectionFooter;