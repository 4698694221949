import React, { Component } from 'react';

class SectionRedes extends Component {
    render() {
        return (
            <section className="section white-section section-padding-top-bottom" style={{backgroundColor: '#f7f6f6'}}>
                <div className="container">
                    <div className="social-contact">
                        <ul className="list-contact">
                            <li className="contact-soc">
                                <a className="tooltip-shop" target="_blank" href="https://www.instagram.com/enigmagamesuy">&#xf16d;<span className="tooltip-content-shop"><span className="tooltip-text-shop"><span className="tooltip-inner-shop">Seguinos!</span></span></span></a>
                            </li>
                            <li className="contact-soc">
                                <a className="tooltip-shop" target="_blank" href="https://www.facebook.com/Enigma-Games-106269838266623">&#xf09a;<span className="tooltip-content-shop"><span className="tooltip-text-shop"><span className="tooltip-inner-shop">Seguinos!</span></span></span></a>
                            </li>
                        </ul>	
                    </div>
                </div>
            </section>
        );
    }
}

export default SectionRedes;