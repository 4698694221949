import React, { Component } from 'react';

class SectionScrollToUp extends Component {
    render() {
        return (
            <div className="scroll-to-top">&#xf106;</div>
        );
    }
}

export default SectionScrollToUp;