import React, { Component } from 'react';

import HeaderTop from '../components/HeaderTop';
import HeaderSlide from '../components/HeaderSlide';
import SectionCategory from '../components/SectionCategory';
import SectionTime from '../components/SectionTime';
import SectionScrollToUp from '../components/SectionScrollToUp';
import SectionFooter from '../components/SectionFooter';
import config from '../config.json';

class HomePage extends Component {

	constructor(){
        super();
        const widthWindow = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.state = {
            width: widthWindow
        }

        var context = this;
        window.addEventListener('resize', function(event) {
            const widthWindow  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            context.updateWidth(widthWindow);
        }, true);
    }
    
    componentDidMount() {
        if (typeof(Storage) !== "undefined") {
            if (sessionStorage.getItem("shopItems") === null || sessionStorage.getItem("shopItems") === undefined || sessionStorage.getItem("shopItems") == []) {
                this.onLoadData()
            }
        }
    }

    onLoadData = () => {
        var request = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            }
        };
        fetch(config.base_url + config.version + 'product/all', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error === 0) {
                if (typeof(Storage) !== "undefined") {
                    var dataJSON = JSON.stringify(data.data);
                    sessionStorage.setItem("shopItems", dataJSON);
                }
            }
        });
    }

	updateWidth = (width) => {
        if (width !== this.state.width) {
            this.setState({
                width: width
            });            
        }
    }
    
	render() {
        return (  
            <div>
                <HeaderTop />
                <main className="cd-main-content" style={{width: this.state.width}}>
                    <HeaderSlide />
                    <SectionCategory />
                    <SectionTime />
                    <SectionFooter />
                </main>
                <SectionScrollToUp />
            </div>
        );
    }
    
}

export default HomePage;