import React, { Component } from 'react';

import HeaderTop from '../components/HeaderTop';
import SectionFaqs from '../components/SectionFaqs';
import SectionScrollToUp from '../components/SectionScrollToUp';
import SectionFooter from '../components/SectionFooter';

class FaqPage extends Component {
    
	render() {
        return (  
            <div>
                <HeaderTop />
                <main className="cd-main-content">
                    <SectionFaqs />
                    <SectionFooter />
                </main>
                <SectionScrollToUp />
            </div>
        );
    }
    
}

export default FaqPage;