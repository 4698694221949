import React, { Component } from 'react';

import config from '../config.json';
import {convertToMoney} from  '../utils/utils';

class SectionCart extends Component {

    constructor(){
        super();
        this.state = {
            items: [],
            itemStock: [],
            mapImages: {},
            envio: 0,
            showDiscount: false
        }
    }
    
	componentWillMount() {
        var json = localStorage.getItem("cartItems")
        if (json !== undefined && json !== null) {
            var list = JSON.parse(json);
            var listCheckStock = [];
            var mapImages = {}
            list.forEach(item => {
                mapImages[item.code] = `${config.GOOGLE_STORAGE}/${config.FIREBASE_STORAGE_BUCKET}/${item.type === 1 ? "products" : "accessories"}/${item.code}/thumbnail`    
                listCheckStock.push({
                    code: item.code,
                    productType: item.type
                });
            });
            this.setState({ 
                items: list,
                mapImages: mapImages 
            });

            //Check stock
            var request = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    products: listCheckStock
                })
            };
            fetch(config.base_url + config.version + 'product/check', request)
            .then(results => { return results.json(); })
            .then(data => {
                this.setState({ itemStock: data.data }) 
            });
        }    
    }

    addQuantity = (code) => {
        var json = localStorage.getItem("cartItems")
        if (json !== undefined && json !== null) {
            var list = JSON.parse(json);
            for (var i=0; i<list.length; i++) {
                if (list[i].code === code) {
                    let quantity = list[i].quantity;
                    //Me fijo si tengo stock
                    this.state.itemStock.forEach(item => {
                        if (code === item[1] && quantity < item[0]) {
                            quantity += 1;
                        }
                    })        
                    list[i].quantity = quantity;
                }
            }
            var dataJSON = JSON.stringify(list);
            localStorage.setItem("cartItems", dataJSON);
            this.setState({ items: list })
            
            this.props.onChangeCart();
        }
    }

    subtractQuantity = (code) => {
        var json = localStorage.getItem("cartItems")
        if (json !== undefined && json !== null) {
            var list = JSON.parse(json);
            for (var i=0; i<list.length; i++) {
                if (list[i].code === code && list[i].quantity > 1) {
                    list[i].quantity -= 1;
                }
            }
            var dataJSON = JSON.stringify(list);
            localStorage.setItem("cartItems", dataJSON);
            this.setState({ items: list })

            this.props.onChangeCart();
        }
    }

    removeItem = (code) => {
        var json = localStorage.getItem("cartItems")
        if (json !== undefined && json !== null) {
            var list = JSON.parse(json);
            var newList = [];
            for (var i=0; i<list.length; i++) {
                if (list[i].code !== code) {
                    newList.push(list[i]);
                }
            }
            var dataJSON = JSON.stringify(newList);
            localStorage.setItem("cartItems", dataJSON);
            this.setState({ items: newList })

            this.props.onChangeCart();
        }
    }

    calculateSubTotalPrice = () => {
        var price = 0;
        for (var i=0; i<this.state.items.length; i++) {
            let item = this.state.items[i]
            let originalPrice = item.price;
            let discount = item.discount;
            if (discount !== 0)
                originalPrice = originalPrice * (1 - discount/100)
            price += originalPrice * item.quantity;
        }
        return price;
    }

    calculateDiscounts = () => {
        let discountAmount = 0
        let validProducts = [];
        this.state.items.forEach(item => {
            if (item.promo3x2)
            {
                for (let i=0; i<item.quantity; i++) {
                    validProducts.push({
                        productCode: item.productCode,
                        price: item.price * (1 - item.discount / 100)
                    });
                }
            }            
        });
        //Ordenamos la lista por precio
        validProducts.sort(this.compararPrecios);

        let itemsToRemove = Math.trunc(validProducts.length / 3);

        for (let i=0; i<itemsToRemove; i++) {
            discountAmount += validProducts[i].price;
        }

        return discountAmount
    }

    compararPrecios = (a, b) => {
        if (a.price > b.price) {
            return 1; // Si a tiene un precio mayor, a debe aparecer después en la lista
        } else if (a.price < b.price) {
            return -1; // Si a tiene un precio menor, a debe aparecer antes en la lista
        } else {
            return 0; // Si tienen el mismo precio, el orden no importa
        }
    }

    calculateTotalItems = () => {
        var count = 0;
        this.state.items.map((item, index) => {
            count += item.quantity
        })

        return count;
    }

    addItems = () => {
        if (this.props.isMobile) {
            const listItems = this.state.items.map((item, index) => {
                return(
                    <div class="three columns" style={{width: '100%'}}>
                        <div class="cart-name item" style={{padding: '10px', height: '130px'}}>
                            <div class="img-cart" style={{display: 'flex', alignItems: 'center'}}>
                                <img alt="" src={this.state.mapImages[item.code]} style={{height: 'auto', maxHeight: '100%'}}/>
                            </div>
                            <div class="data-cart">
                                <div class="remove" style={{top: '10px', right: '10px'}} onClick={() => this.removeItem(item.code)}></div>
                                <p style={{marginTop: '10px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontFamily: 'Lato', fontWeight: '400', fontSize: '13px', letterSpacing: '2px', color: '#111111', marginBottom: '0'}}>{item.name}</p>
                                <p style={{fontFamily: 'Lato', fontWeight: '550', fontSize: '13px', letterSpacing: '2px', color: '#111111', marginBottom: '0'}}>$ {convertToMoney(item.price)}</p>
                                <div class="cart-name" style={{margin: '0 auto', maxWidth: '200px', paddingLeft: '20px', paddingRight: '10px', paddingTop: '5px'}}>
                                    <div class={item.quantity==1?'num-itm-ic cart-item':'num-itm-ic'} onClick={() => this.subtractQuantity(item.code)} style={{marginTop: '0px'}}></div>
                                    <div class="num-itm" style={{marginTop: '0px'}}>{item.quantity}</div>
                                    <div class={(item.quantity==3 || (this.state.itemStock != undefined && this.state.itemStock.length > 0 &&this.state.itemStock[index][0] <= item.quantity))?'num-itm-ic cart-item':'num-itm-ic'} onClick={() => this.addQuantity(item.code)} style={{marginTop: '0px'}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
            return (<div>{listItems}</div>);
        } else {
            const listItems = this.state.items.map((item, index) => {
                let originalPrice = item.price;
                let discount = item.discount;
                if (discount !== 0)
                    originalPrice = originalPrice * (1 - discount/100)
                return(
                    <div class="row">
                        <div class="col-lg-6" style={{paddingRight: '4px', paddingLeft: '4px'}}>
                            <div class="cart-name item image-item">
                                <img alt="" src={this.state.mapImages[item.code]} />
                                <h6 style={{paddingLeft: '95px', marginTop: '3px', lineHeight: '35px'}}>{item.name}</h6>
                                <div class="remove" onClick={() => this.removeItem(item.code)}></div>
                            </div>
                        </div>
                        <div class="col-lg-2" style={{paddingRight: '4px', paddingLeft: '4px'}}>
                            <div class="cart-name item"><h6 style={{marginTop: '3px'}}>$ {convertToMoney(originalPrice)}</h6></div>
                        </div>
                        <div class="col-lg-2" style={{paddingRight: '4px', paddingLeft: '4px'}}>
                            <div class="cart-name item">
                                <div class={item.quantity==1?'num-itm-ic cart-item':'num-itm-ic'} onClick={() => this.subtractQuantity(item.code)}></div>
                                <div class="num-itm">{item.quantity}</div>
                                <div class={(item.quantity==3 ||(this.state.itemStock != undefined && this.state.itemStock.length > 0 && this.state.itemStock[index][0] <= item.quantity))?'num-itm-ic cart-item':'num-itm-ic'} onClick={() => this.addQuantity(item.code)}></div>
                            </div>
                        </div>
                        <div class="col-lg-2" style={{paddingRight: '4px', paddingLeft: '4px'}}>
                            <div class="cart-name item"><h6 style={{marginTop: '3px'}}>$ {convertToMoney(item.quantity * originalPrice)}</h6></div>
                        </div>
                    </div>
                );
            });
            return (<div class="container">{listItems}</div>);
        }
    }

    onClickBuy = () => {
        window.location.href = "/checkout";
    }

    render() {
        if (this.state.items.length === 0) {
            return (
                <section className="section grey-section section-padding-top-bottom" style={{paddingBottom: '50px'}}>
                    <div className="container">
                        <div style={{textAlign: 'center', marginTop: '40px'}}>
                            <img src="/images/empty_cart.png" width='200px' height='200px'/>
                            <h6 style={{marginTop:'30px', fontSize: '20px'}}>Ir a la <a href="/shop">tienda</a></h6>
                        </div>
                    </div>
                </section>	
            );
        } else {
            if (this.props.isMobile) {
                return (
                    <section className="section grey-section section-padding-top-bottom">
                        <div className="container" style={{width:'90%'}}>
                            <div className="sixteen columns">
                                <div className="cart-full">
                                    <h6>Tienes {this.calculateTotalItems()} artículo{this.calculateTotalItems() > 1 ? 's' : ''} en tu carrito.</h6>
                                </div>
                            </div>
                            <div class="clear"></div>
    
                            { this.addItems() }
                            
                            <div class="sixteen columns" style={{width: '100%'}}>
                                <div class="cart-totals">
                                    
                                <h6 className='resumeList'>Subtotal del pedido <span>$ {convertToMoney(this.calculateSubTotalPrice() + this.state.envio)}</span></h6>
                                    <h6 style={{display: this.calculateDiscounts() > 0?'block':'none'}} className='resumeList'>Descuentos <span>-$ {convertToMoney(this.calculateDiscounts())}</span></h6>
                                    <hr style={{marginBottom: '10px', marginTop: '10px', marginLeft: '0px', marginRight: '0px', borderTop: '1px solid #000'}}></hr>
                                    <h5 className='resumeList'>Total del pedido <span>$ {convertToMoney(this.calculateSubTotalPrice() + this.state.envio - this.calculateDiscounts())}</span></h5>
                                </div>
                            </div>
                     
                                <div style={{width: '100%', display: 'inline-flex', paddingTop: '30px'}}>
                                    <div id="finish-button" onClick={() => this.onClickBuy()} 
                                        className="button-shortcodes text-size-1 text-padding-1 version-1" 
                                        style={{margin: 'auto', display: (this.state.items.length > 0)?'block':'none'}}>
                                        Comprar
                                    </div>   
                                </div>                                   
                                
                        </div>
                    </section>
                );
            } else {
                return (
                    <section className="section grey-section section-padding-top-bottom">
                        <div className="container">
                            <div className="sixteen columns">
                                <div className="cart-full">
                                    <h6>Tienes {this.calculateTotalItems()} artículo{this.calculateTotalItems() > 1 ? 's' : ''} en tu carrito.</h6>
                                </div>
                            </div>
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6" style={{paddingRight: '4px', paddingLeft: '4px'}}>
                                        <div className="cart-name"><h5>Producto</h5></div>
                                    </div>
                                    <div class="col-lg-2" style={{paddingRight: '4px', paddingLeft: '4px'}}>
                                        <div className="cart-name"><h5>Precio</h5></div>
                                    </div>
                                    <div class="col-lg-2" style={{paddingRight: '4px', paddingLeft: '4px'}}>
                                        <div className="cart-name"><h5>Cantidad</h5></div>
                                    </div>
                                    <div class="col-lg-2" style={{paddingRight: '4px', paddingLeft: '4px'}}>
                                        <div className="cart-name"><h5>sub-total</h5></div>
                                    </div>
                                </div>
                            </div>
                            <div class="clear"></div>
    
                            { this.addItems() }
    
                            <div class="container" style={{padding: '4px'}}>
                                <div class="cart-totals">
                                    <h6 className='resumeList'>Subtotal del pedido <span>$ {convertToMoney(this.calculateSubTotalPrice() + this.state.envio)}</span></h6>
                                    <h6 style={{display: this.calculateDiscounts() > 0?'block':'none'}} className='resumeList'>Descuentos <span>-$ {convertToMoney(this.calculateDiscounts())}</span></h6>
                                    <hr style={{marginBottom: '10px', marginTop: '10px', marginLeft: '0px', marginRight: '0px', borderTop: '1px solid #000'}}></hr>
                                    <h5 className='resumeList'>Total del pedido <span>$ {convertToMoney(this.calculateSubTotalPrice() + this.state.envio - this.calculateDiscounts())}</span></h5>
                                </div>
                            </div>
         
                                <div style={{width: '100%', display: 'inline-flex', paddingTop: '30px'}}>
                                    <div id="finish-button" onClick={() => this.onClickBuy()} 
                                        className="button-shortcodes text-size-1 text-padding-1 version-1" 
                                        style={{margin: 'auto', display: (this.state.items.length > 0)?'block':'none'}}>
                                        Comprar
                                    </div>   
                                </div>                                   
                                   
                        </div>
                    </section>
                );
            }
        }
    }
}

export default SectionCart;