import React, { Component } from 'react';

class SectionTime extends Component {
    render() {
        return (
            <section className="section white-section section-padding-top-bottom">
                <div className="container">
                    <div style={{ padding: '15px', marginTop: '20px', marginBottom: '20px'}}>
                        <div className="section-title">
                            <h3>El camino a la diversión</h3>
                            <div className="subtitle">Compartimos tu <span className="subtitle-written">pasión</span> por los juegos de mesa, y si aún  no la descubriste, te ayudamos a hacerlo 😉</div>
                        </div>
                    </div>
                    <div className="sixteen columns">
                        <div id="cd-timeline" className="cd-container">
                            <div className="cd-timeline-block">
                                <div className="cd-timeline-img">&#xf002;</div>
                                <div className="cd-timeline-content">
                                    <h6><b>Elegí el juego</b></h6>
                                    <p>Visitá nuestra tienda y encontrá el juego perfecto para cada ocasión.</p>
                                    <a href="/shop" className="cd-read-more">TIENDA</a>
                                    <span className="cd-date">Paso 1</span>
                                </div>
                            </div>

                            <div className="cd-timeline-block">
                                <div className="cd-timeline-img">&#xf07a;</div>
                                <div className="cd-timeline-content">
                                    <h6><b>Hacé tu pedido</b></h6>
                                    <p style={{paddingBottom: '5px'}}>Confirmá tu pedido y pagalo con el método de pago que más te guste.</p>                                    
                                    <span className="cd-date">Paso 2</span>
                                </div>
                            </div>

                            <div className="cd-timeline-block">
                                <div className="cd-timeline-img">&#xf135;</div>
                                <div className="cd-timeline-content">
                                    <h6><b>Recibilo donde quieras</b></h6>
                                    <p style={{paddingBottom: '0px'}}>Realizamos envíos a domicilio dentro de Montevideo y a través de agencias al resto del país.</p>
                                    <span className="cd-date">Paso 3</span>
                                </div>
                            </div>

                            <div className="cd-timeline-block">
                                <div className="cd-timeline-img">&#xf144;</div>
                                <div className="cd-timeline-content">
                                    <h6><b>¡DISFRUTALO!</b></h6>
                                    <p style={{paddingBottom: '0px'}}>Llegó el paso más importante, JUGARLO. <br/>Compartí esta experiencia con amigos y familia!</p>
                                    <span class="cd-date">Paso 4</span>
                                </div>
                            </div>

                            <div className="cd-timeline-block">
                                <div className="cd-timeline-img">&#xf005;</div>
                                <div className="cd-timeline-content">
                                    <h6><b>Contanos tu experiencia</b></h6>
                                    <p style={{paddingBottom: '0px'}}>Tu opinión nos ayuda a mejorar!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default SectionTime;