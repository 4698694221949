import React, { Component } from 'react';

import HeaderTop from '../components/HeaderTop';
import SectionScrollToUp from '../components/SectionScrollToUp';
import SectionFooter from '../components/SectionFooter';
import SectionShopFilters from '../components/SectionShopFilters';
import SectionItemShop from '../components/SectionItemShop';
import PaginationComponent from '../components/PaginationComponent';
import config from '../config.json';
import {convertToMoney} from  '../utils/utils';

class ShopPage extends Component {

	constructor(){
        super();
        const widthWindow = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const heightWindow  = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        this.state = {
            from: 1,
            to: 9,
            qtyItems: 9,
            showFilterInBar: false,
            items: [],
            filteredItems: [],
            inputSearch: '',
            priceFrom: -1,
            priceTo: -1,
            categories: [],
            type: undefined,
            players: undefined,
            difficulty: [],
            other: [],
            onlyStock: false,
            changeDataCart: 0,
            order: 1,
            width: widthWindow,
            height: heightWindow,
            loaded: false
        }

        var context = this;
        window.addEventListener('resize', function(event) {
            const widthWindow  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const heightWindow  = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            context.updateWidth(widthWindow, heightWindow);
        }, true);
    }

    removeSpecialCharacter = (text) => {
        let value = text.toLowerCase();
        value = value.replaceAll('á', 'a');
        value = value.replaceAll('é', 'e');
        value = value.replaceAll('í', 'i');
        value = value.replaceAll('ó', 'o');
        value = value.replaceAll('ú', 'u');
        value = value.replace(/[&\/\\#,+()$~%.'":*?<>{}!¿?¡]/g, '');
        return value
    }

	componentDidMount() {
        var value = "";

        var search = localStorage.getItem("search")
        if (search !== null && search !== undefined && search !== ''){
            localStorage.removeItem("search");
            value = Buffer.from(search, 'base64').toString('ascii');
            value = this.removeSpecialCharacter(value);
            this.setState({ inputSearch: value })
        }

        if (typeof(Storage) !== "undefined") {
            // Me fijo si ya tengo la data en cache.
            let refresh = Date.now() - sessionStorage.getItem("loadDate") > 3600000
            if (!refresh && sessionStorage.getItem("shopItems") !== null && sessionStorage.getItem("shopItems") !== undefined && sessionStorage.getItem("shopItems").length > 0) {
                var json = sessionStorage.getItem("shopItems")
                var list = JSON.parse(json);
                this.setState({ loaded: true, items: list, filteredItems: list });
                this.filterData(list, value, -1, -1, [], undefined, undefined, [], [], false, 1)

            } else {
                this.onLoadData(value);
            }
        } else {
            // No tiene soporte de storage
            this.onLoadData(value);
        }
    }

    onLoadData = (input) => {
        var request = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            }
        };
        fetch(config.base_url + config.version + 'product/all', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error === 0) {
                if (typeof(Storage) !== "undefined") {
                    var dataJSON = JSON.stringify(data.data);
                    sessionStorage.setItem("shopItems", dataJSON);
                    sessionStorage.setItem("loadDate", Date.now());
                }
                if (input !== undefined && input !== '') {
                    this.setState({ loaded: true, items: data.data });
                    this.filterData(data.data, input, -1, -1, [], undefined, undefined, [], [], false, 1)
                } else {
                    this.setState({ loaded: true, items: data.data, filteredItems: data.data });
                    this.filterData(data.data, "", -1, -1, [], undefined, undefined, [], [], false, 1)
                }
            } else {
                alert("Ocurrió un error, vuelva a intentar luego!")
            }
        });
    }

    handleAddCart = () => {
        var data = this.state.changeDataCart + 1;
        this.setState({
            changeDataCart: data
        });
    }

    updateWidth = (width, height) => {
        if (width !== this.state.width) {
            this.setState({
                width: width,
                height: height
            });            
        }
    }

    filterData = (items, inputSearch, priceFrom, priceTo, categories, players, type, difficulty, other, onlyStock, order) => {
        //Filter search
        var temporal = items;
        var filtered = [];
        if (inputSearch !== undefined && inputSearch !== '') {
            temporal.forEach(item => {
                let item_name = this.removeSpecialCharacter(item.name);
                if (item_name.includes(inputSearch)) {
                    filtered.push(item);
                }
            });
        } else {
            filtered = temporal;
        }
        //Filter price
        temporal = filtered;
        filtered = [];
        var priceF = parseInt(priceFrom);
        var priceT = parseInt(priceTo);
        if (priceF > 0 || priceT > 0) {
            temporal.forEach(item => {
                var itemPrice = parseInt(item.price);
                if (priceF > 0 && priceT > 0 && itemPrice >= priceF && itemPrice <= priceT) {
                    filtered.push(item);
                }
                else if (priceF > 0 && priceT < 0 && itemPrice >= priceF) {
                    filtered.push(item);
                }
                else if (priceF < 0 && priceT > 0 && itemPrice <= priceT) {
                    filtered.push(item);
                }
            });
        } else {
            filtered = temporal;
        }
        //Filter categories
        temporal = filtered;
        filtered = [];
        temporal.forEach(item => {
            if (categories.length === 0 || categories.includes(item.category)) {
                filtered.push(item);
            }
        });
        //Filter players quantity
        temporal = filtered;
        filtered = [];
        if (players === undefined) {
            filtered = temporal;
        } else {
            temporal.forEach(item => {
                if (players >= item.minPlayers && players <= item.maxPlayers) {
                    filtered.push(item);
                }
            });
        }
        //Filter type
        temporal = filtered;
        filtered = [];
        if (type === undefined) {
            filtered = temporal;
        } else {
            temporal.forEach(item => {
                if (type === item.type) {
                    filtered.push(item);
                }
            });
        }
        //Filter difficulty
        temporal = filtered;
        filtered = [];
        if (difficulty.length === 0) {
            filtered = temporal;
        } else {
            temporal.forEach(item => {
                if (difficulty.includes(item.difficulty)) {
                    filtered.push(item);
                }
            });
        }
        //Filter others
        temporal = filtered;
        filtered = [];
        if (other.length === 0) {
            filtered = temporal;
        } else {
            temporal.forEach(item => {
                if (other.includes('cooperativo') && item.cooperative === 1) {
                    filtered.push(item);
                }
                else if (other.includes('expansion') && item.expansion === 1) {
                    filtered.push(item);
                }
                else if (other.includes('para_dos') && item.forTwo === 1) {
                    filtered.push(item);
                }
                else if (other.includes('solitario') && item.lonely === 1) {
                    filtered.push(item);
                }
            });
        }
        //Filter stock
        temporal = filtered;
        filtered = [];
        if (!onlyStock) {
            filtered = temporal;
        } else {
            temporal.forEach(item => {
                if (item.stock > 0) {
                    filtered.push(item);
                }
            });
        }
        //Order list
        if (parseInt(order) === 1) {
            filtered.sort((a,b) => this.orderPopularity(a,b))
        } else if (parseInt(order) === 2) {            
            filtered.sort((a,b) => (a.finalPrice > b.finalPrice) ? 1 : ((b.finalPrice > a.finalPrice) ? -1 : 0))
        } else if (parseInt(order) === 3) {
            filtered.sort((a,b) => (a.finalPrice > b.finalPrice) ? -1 : ((b.finalPrice > a.finalPrice) ? 1 : 0))
        }

       this.setState({ filteredItems: filtered });
    }

    orderPopularity = (a, b) => {
        if (a.popularity === undefined) {
            return 1
        } else if (b.popularity === undefined) {
            return -1
        } else {
            if (a.popularity > b.popularity) {
                return -1
            } else if (a.popularity < b.popularity) {
                return 1
            } else {
                return 0
            }
        }
    }

    onChangeInput = (input) => {
        input = this.removeSpecialCharacter(input);
        this.filterData(this.state.items, input, this.state.priceFrom, this.state.priceTo, this.state.categories, this.state.players, this.state.type, 
                        this.state.difficulty, this.state.other, this.state.onlyStock, this.state.order)
        this.setState({ inputSearch: input });
    }

    onChangeMinPrice = (e) => {
        var input = e.target.value;
        input = input.replace(".", "");
        if (input.length > 5){
            input = input.slice(0, -1);
        }
        else if (input === '') {
            input = -1;
        }
        this.filterData(this.state.items, this.state.inputSearch, input, this.state.priceTo, this.state.categories, this.state.players, this.state.type, 
                        this.state.difficulty, this.state.other, this.state.onlyStock, this.state.order)
        this.setState({ priceFrom: input });
        if (input > 0) {
            input = convertToMoney(input)
            e.target.value = input;
        } else {
            e.target.value = '';
        }
    }

    onChangeMaxPrice = (e) => {
        var input = e.target.value;
        input = input.replace(".", "");
        if (input.length > 5){
            input = input.slice(0, -1);
        }
        else if (input === '') {
            input = -1;
        }
        this.filterData(this.state.items, this.state.inputSearch, this.state.priceFrom, input, this.state.categories, this.state.players, this.state.type, 
                        this.state.difficulty, this.state.other, this.state.onlyStock, this.state.order)
        this.setState({ priceTo: input });
        if (input > 0) {
            input = convertToMoney(input)
            e.target.value = input;
        } else {
            e.target.value = '';
        }
    }

    onSelectCategory = (e) => {
        var value = parseInt(e.target.id);
        var checked = e.target.checked;
        var list = this.state.categories;
        if (checked) {
            list.push(value);
        } else {
            const index = list.indexOf(value);
            if (index > -1) {
                list.splice(index, 1);
            }
        }
        this.setState({ categories: list });
        this.filterData(this.state.items, this.state.inputSearch, this.state.priceFrom, this.state.priceTo, list, this.state.players, this.state.type, 
                        this.state.difficulty, this.state.other, this.state.onlyStock, this.state.order)
    }

    onSelectStock = (e) => {
        var checked = e.target.checked;
        this.setState({onlyStock: checked});
        this.filterData(this.state.items, this.state.inputSearch, this.state.priceFrom, this.state.priceTo, this.state.categories, this.state.players, 
                        this.state.type, this.state.difficulty, this.state.other, checked, this.state.order)
    }

    onSelectType = (typeSelected) => {
        this.setState({ type: typeSelected });
        this.filterData(this.state.items, this.state.inputSearch, this.state.priceFrom, this.state.priceTo, this.state.categories, this.state.players, 
                        typeSelected, this.state.difficulty, this.state.other, this.state.onlyStock, this.state.order)
    }

    onSelectPlayers = (playersSelected) => {
        this.setState({ players: playersSelected });
        this.filterData(this.state.items, this.state.inputSearch, this.state.priceFrom, this.state.priceTo, this.state.categories, playersSelected, 
                        this.state.type, this.state.difficulty, this.state.other, this.state.onlyStock, this.state.order)
    }

    onSelectDifficulty = (e) => {
        var value = parseInt(e.target.id);
        var checked = e.target.checked;
        var list = this.state.difficulty;
        if (checked) {
            list.push(value);
        } else {
            const index = list.indexOf(value);
            if (index > -1) {
                list.splice(index, 1);
            }
        }
        this.setState({ difficulty: list });
        this.filterData(this.state.items, this.state.inputSearch, this.state.priceFrom, this.state.priceTo, this.state.categories, this.state.players, 
                        this.state.type, list, this.state.other, this.state.onlyStock, this.state.order)
    }

    onSelectOther = (e) => {
        var value = e.target.id;
        var checked = e.target.checked;
        var list = this.state.other;
        if (checked) {
            list.push(value);
        } else {
            const index = list.indexOf(value);
            if (index > -1) {
                list.splice(index, 1);
            }
        }
        this.setState({ other: list });
        this.filterData(this.state.items, this.state.inputSearch, this.state.priceFrom, this.state.priceTo, this.state.categories, this.state.players, 
                        this.state.type, this.state.difficulty, list, this.state.onlyStock, this.state.order)
    }

    onChangeDataPagination = (from, to) => {
        this.setState({
            from: from,
            to: to
        });
    }

    renderSectionFilters = () => {
        if (this.state.width >= 1170 && this.state.width <= 1500) {
            return (
                <div className="col-3">
                    <SectionShopFilters
                        defaultSearch={this.state.inputSearch}
                        onChangeInput={this.onChangeInput}
                        onChangeMinPrice={this.onChangeMinPrice}
                        onChangeMaxPrice={this.onChangeMaxPrice}
                        onSelectDifficulty={this.onSelectDifficulty}
                        onSelectType={this.onSelectType}
                        onSelectPlayers={this.onSelectPlayers}
                        onSelectCategory={this.onSelectCategory}
                        onSelectOther={this.onSelectOther}
                        onSelectStock={this.onSelectStock}
                    />
                </div>
            );
        } 
        else if (this.state.width > 1500) {
            return (
                <div className="col-2">
                    <SectionShopFilters
                        defaultSearch={this.state.inputSearch}
                        onChangeInput={this.onChangeInput}
                        onChangeMinPrice={this.onChangeMinPrice}
                        onChangeMaxPrice={this.onChangeMaxPrice}
                        onSelectDifficulty={this.onSelectDifficulty}
                        onSelectType={this.onSelectType}
                        onSelectPlayers={this.onSelectPlayers}
                        onSelectCategory={this.onSelectCategory}
                        onSelectOther={this.onSelectOther}
                        onSelectStock={this.onSelectStock}
                    />
                </div>
            );
        }
    }

    onSelectOrder = (e) => {
        var value = e.target.value;
        this.setState({order: value});
        this.filterData(this.state.items, this.state.inputSearch, this.state.priceFrom, this.state.priceTo, this.state.categories, this.state.players, 
                        this.state.type, this.state.difficulty, this.state.other, this.state.onlyStock, value)
    }

    renderOrderFilter = () => {
        return(
            <div className="row">
                <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                    <div className="col-md-12" style={{paddingRight: '15px'}}>
                        <div className="pull-right" >
                            <select onChange={(e) => this.onSelectOrder(e)} style={{cursor: 'pointer'}} defaultValue={this.state.order}>
                                <option value="1">más populares</option>
                                <option value="2">menor precio</option>
                                <option value="3">mayor precio</option>
                            </select>
                        </div>
                        <div className="pull-right">
                            <b>Ordenar por</b>
                        </div>
                    </div>
                </div>    
            </div>
        );
    }

    renderSectionItems = () => {
        if (this.state.width >= 1170 && this.state.width <= 1500) {
            return (
                <div className="col-9">
                    { this.renderOrderFilter() }
                    { this.onShowData() }
                </div>
            );
        } else if (this.state.width > 1500) {
            return (
                <div className="col-10">
                    { this.renderOrderFilter() }
                    { this.onShowData() }
                </div>
            );
        } else {
            return (
                <div className="col-12">
                    { this.renderOrderFilter() }
                    { this.onShowData() }
                </div>
            );
        }
    }

    onShowData = () => {
        var list = [];
        var classQuantity = "col-6";
        var showFilter = true;
        var quantity = 10;
        if (this.state.width > 1500) {
            classQuantity = "col-3";
            quantity = 12;
            showFilter = false;
        } else if (this.state.width >= 1170 && this.state.width <= 1500) {
            classQuantity = "col-4";
            quantity = 9;
            showFilter = false;
        } else if (this.state.width >= 1000 && this.state.width <= 1169) {
            classQuantity = "col-3";
            quantity = 12;
            showFilter = true;
        } else if (this.state.width >= 600 && this.state.width <= 999) {
            classQuantity = "col-4";
            quantity = 9;
            showFilter = true;
        }

        if (this.state.qtyItems !== quantity || this.state.showFilterInBar !== showFilter) {
            this.setState({ 
                qtyItems: quantity, 
                showFilterInBar: showFilter
            });
        }

        var position = 0;
        for(var i=this.state.from-1; i<this.state.to; i++) {
            if (i < this.state.filteredItems.length) {
                var customClass = classQuantity;
                if (this.state.width < 600) {
                    if (position % 2) {
                        customClass = customClass + ' par-shop'
                    } else {
                        customClass = customClass + ' impar-shop'
                    }
                }

                var item = this.state.filteredItems[i];
                list.push(
                    <SectionItemShop
                        position={position}
                        classQuantity={customClass}
                        code={item.code}
                        path={item.path}
                        type={item.productType}
                        name={item.name}
                        price={item.price}
                        prefix={item.prefix}
                        stock={item.stock}
                        promo3x2={item.promo3x2}
                        onAddToCart={ this.handleAddCart }
                        discount={item.discount} />
                );
                
                position += 1;
            }
        }

        if (list.length === 0) {
            return(
                <div className="row">
                    <div style={{margin: 'auto', width: '39%'}}>
                        <div style={{textAlign: 'center'}}>
                            <img alt="Empty state" src="/images/empty_state.png"/>
                        </div>
                    </div>
                </div>
            );
        } else {
            return(
                <div className="row">{ list }</div>
            );
        }
    }

    renderShop = () => {
        if (!this.state.loaded) {
            return (
                <div className="loader" style={{display:'block'}}></div>
            );
        } else {
            return (
                    
                    <main className="cd-main-content" style={{width: this.state.width}}>
                        {/** SHOP PAGE **/}
                        <section className="section white-section section-padding-top-bottom">
                            <div className="container2 shopMarginTop">
                                <div className="row">
                                    { this.renderSectionFilters() }
                                    { this.renderSectionItems() }                                
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <PaginationComponent 
                                            totalItems={ this.state.filteredItems.length }
                                            quantityRows={ this.state.qtyItems }
                                            onChangeData={ (from, to) => this.onChangeDataPagination(from, to) }/>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <SectionFooter />
                    <SectionScrollToUp />
                    </main>
            )
        }
    }

	render() {       
        return (  
            <div>
                {/** MODAL FILTERS **/}
                <div className="modal" id="filterModal" role="dialog" style={{zIndex: '99999'}}>
                    <div className="modal-dialog" style={{margin: 0}}>
                        <div id="modal-content" className="modal-content" style={{overflow: 'auto', margin: '12px', height: (this.state.height - 24), width: (this.state.width - 24) }}>
                            <div className="modal-header">
                                <h4 className="modal-title">Filtros</h4>
                                <i data-toggle="modal" className="btn btn-default dismissDialog fa fa-fw" data-dismiss="modal"></i>
                            </div>
                            <div className="modal-body">
                                <SectionShopFilters
                                    defaultSearch={this.state.inputSearch}
                                    onChangeInput={this.onChangeInput}
                                    onChangeMinPrice={this.onChangeMinPrice}
                                    onChangeMaxPrice={this.onChangeMaxPrice}
                                    onSelectDifficulty={this.onSelectDifficulty}
                                    onSelectType={this.onSelectType}
                                    onSelectPlayers={this.onSelectPlayers}
                                    onSelectCategory={this.onSelectCategory}
                                    onSelectOther={this.onSelectOther}
                                    onSelectStock={this.onSelectStock}
                                />
                            </div>
                            <div className="modal-footer">
                                <button style={{height:'35px'}} type="button" className="btn btn-default" data-dismiss="modal">Aplicar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <HeaderTop dataCart={this.state.changeDataCart} showFilter={this.state.showFilterInBar}/>
                {this.renderShop()}                    
            </div>
        )
    }
}
    


export default ShopPage;