import React, { Component } from 'react';

class SectionCartTop extends Component {

    constructor(){
        super();
        this.state = {
            items: []
        }
	}

    render() {
        return (
            <section className="section white-section section-home-padding-top" >
                <div className="container">
                    <div>
                        <div className="section-title left">
                            <h2 style={{textAlign: 'center'}}>Carrito</h2>
                        </div>
                    </div>
                </div>    
            </section>
        );
    }
}

export default SectionCartTop;