import React, { Component } from 'react';

class SectionResultPurchase extends Component {
    
    scrollToTopPage = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    
    render() {
        this.scrollToTopPage();

        let image = ""
        switch (this.props.result.result) {
            case "fail":
                image = "./images/results/Purchase-Fail.png"
                break;
            case "success":
                image = "./images/results/Purchase-Success.png"
                break;
            case "pending":
                if (this.props.result.shippingType == "2")                
                    image = "./images/results/Purchase-Pending-Retiro.png"
                else
                    image = "./images/results/Purchase-Pending-Envio.png"
                break;
            default:
                break;
        }

        return (
            <section className="section white-section section-home-padding-top">
                <div className="row" >
                    <div className="col-xs-12 flexAlignCenter">
                        <div>
                            <div className="row" >
                                <div className="col-xs-12 flexAlignCenter">
                                    <img align="center" src={image} style={{width:"400px", height:"400px"}} alt={this.props.result.result} />
                                </div> 
                            </div>
                            <div className="row" >
                                <div className="col-xs-12 returnText" >
                                    <a  style={{color: "gray"}} href="/shop">Volver a la tienda</a>                                   
                                </div> 
                            </div> 
                        </div>                        
                    </div>                      
                </div>                
            </section>
        );
    }
}

export default SectionResultPurchase;