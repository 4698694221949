import React, { Component } from "react";

class PaginationComponent extends Component {

	constructor(){
        super();
        this.state = {
            pageSelected: 1,
            quantityRows: 9
		}
	}

	componentWillMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.totalItems !== this.props.totalItems || prevProps.quantityRows !== this.props.quantityRows) {
            this.setState({
                pageSelected: 1,
                quantityRows: this.props.quantityRows
            });
            this.updateData(1, this.props.quantityRows);
        }
    }

    calculateShowFrom = () => {
        return ((this.state.pageSelected-1)*this.state.quantityRows+1);
    }

    calculateShowTo = () => {
        var to = this.state.pageSelected*this.state.quantityRows;
        if (to > this.props.totalItems) {
            to = this.props.totalItems;
        }
        return to;
    }

    calculateQuantityOfPages = () => {
        var div = Math.trunc(this.props.totalItems / this.state.quantityRows);
        var rem = this.props.totalItems % this.state.quantityRows;
        var pages = div;
        if (rem > 0) {
            pages += 1;
        }
        return pages;
    }

    onClickButtonNext = () => {
        var page = parseInt(this.state.pageSelected);
        var quantity = parseInt(this.calculateQuantityOfPages());
        if (page < quantity) {
            page += 1;
            this.setState({pageSelected: page});
            this.updateData(page);

            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    onClickButtonPrev = () => {
        var page = parseInt(this.state.pageSelected);
        if (page > 1) {
            page -= 1;
            this.setState({pageSelected: page});
            this.updateData(page);

            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    onClickButton (e) {
        if (this.state.pageSelected !== e.target.id) {
            this.setState({pageSelected: e.target.id});
            this.updateData(e.target.id);

            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    updateData = (pageSelected, quantityRows) => {
        var quantity = this.state.quantityRows;
        if (quantityRows !== undefined) {
            quantity = quantityRows;
        }

        var from = ((pageSelected-1) * quantity + 1);
        var to = pageSelected * quantity;
        if (to > this.props.totalItems) {
            to = this.props.totalItems;
        }
        this.props.onChangeData(from, to)
    }

    renderItemsButtons = () => {
        var quantity = this.calculateQuantityOfPages();
        var listItems = [];
        if (quantity <= 7) {
            listItems = [];
            for (var i = 1; i <= quantity; i++) {
                listItems.push(
                    <a href="#/" key={i} id={i} onClick={(e) => this.onClickButton(e) } className={`paginate_button ${parseInt(this.state.pageSelected) === i ? 'active' : ''}`}>{ i }</a>
                );
            }
        }
        else if (this.state.pageSelected < 5) {
            listItems = [];
            for (var j = 1; j <= 5; j++) {
                listItems.push(
                    <a href="#/" key={j} id={j} onClick={(e) => this.onClickButton(e) } className={`paginate_button ${parseInt(this.state.pageSelected) === j ? 'active' : ''}`}>{ j }</a>
                );
            }
            listItems.push(<a href="#/" className="pagination_ellipsis">...</a>);
            listItems.push(<a href="#/" id={quantity} onClick={(e) => this.onClickButton(e) } className="paginate_button">{ quantity }</a>);
        }
        else if (this.state.pageSelected > quantity - 4) {
            listItems = [];
            listItems.push(<a href="#/" id={1} onClick={(e) => this.onClickButton(e) } className="paginate_button">{ 1 }</a>);
            listItems.push(<a href="#/" className="pagination_ellipsis">...</a>);
            for (var k = (quantity - 4); k <= quantity; k++) {
                listItems.push(
                    <a href="#/" key={k} id={k} onClick={(e) => this.onClickButton(e) } className={`paginate_button ${parseInt(this.state.pageSelected) === k ? 'active' : ''}`}>{ k }</a>
                );
            }
        }
        else {
            listItems = [];
            listItems.push(<a href="#/" id={1} onClick={(e) => this.onClickButton(e) } className="paginate_button">{ 1 }</a>);
            listItems.push(<a href="#/" className="pagination_ellipsis">...</a>);
            listItems.push(<a href="#/" id={parseInt(this.state.pageSelected) -1} onClick={(e) => this.onClickButton(e) } className="paginate_button">{ parseInt(this.state.pageSelected) - 1 }</a>);
            listItems.push(<a href="#/" id={parseInt(this.state.pageSelected)} onClick={(e) => this.onClickButton(e) } className="paginate_button active">{ parseInt(this.state.pageSelected) }</a>);
            listItems.push(<a href="#/" id={parseInt(this.state.pageSelected) + 1} onClick={(e) => this.onClickButton(e) } className="paginate_button">{ parseInt(this.state.pageSelected) + 1 }</a>);
            listItems.push(<a href="#/" className="pagination_ellipsis">...</a>);
            listItems.push(<a href="#/" id={quantity} onClick={(e) => this.onClickButton(e) } className="paginate_button">{ quantity }</a>);
        }
        return(<span> { listItems } </span>);
    }

	render() {
        if (this.calculateQuantityOfPages() > 0) {
            return (  
                <div className="pagination">
                    <a href="#/" className={`${this.state.pageSelected === 1 ? 'pag_button_disabled' : 'paginate_button'}`} onClick={() => this.onClickButtonPrev() }>&lt;</a>
                    { this.renderItemsButtons() }
                    <a href="#/" className={`${this.state.pageSelected === this.calculateQuantityOfPages() ? 'pag_button_disabled' : 'paginate_button'}`} onClick={() => this.onClickButtonNext() }>&gt;</a>
                </div>
            );
        } else {
            return (<div></div>);
        }
    }
}

export default PaginationComponent;