import React, { Component } from 'react';

import config from '../config.json';

class PaymentPage extends Component {

    componentWillMount() {
        var search = this.props.location.search;
        let preferenceId = new URLSearchParams(search).get("preference_id")
        let purchaseId = new URLSearchParams(search).get("external_reference")
        let paymentId = new URLSearchParams(search).get("payment_id")
        console.log("preferenceId: ", preferenceId)
        var request = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                purchaseId: parseInt(purchaseId),
                preferenceId: preferenceId,
                paymentId: paymentId
            })
        };

        fetch(config.base_url + config.version + 'ticket/checkPayment', request)
        .then(results => { return results.json(); })
        .then(data => {
            console.log(data)
            document.getElementById("status").innerText = JSON.stringify(data.data);
        })
    }

	render() {
        return (  
            <div id = "status">
               EXITO
            </div>
        );
    }
    
}

export default PaymentPage;