import React, { Component } from 'react';

import HeaderTop from '../components/HeaderTop';
import SectionScrollToUp from '../components/SectionScrollToUp';
import SectionFooter from '../components/SectionFooter';
import ContactTop from '../components/ContactTop';
import ContactForm from '../components/ContactForm';
import SectionRedes from '../components/SectionRedes';

class HomePage extends Component {

	constructor(){
        super();

        const widthWindow = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.state = {
            width: widthWindow
        }

        var context = this;
        window.addEventListener('resize', function(event) {
            const widthWindow  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            context.updateWidth(widthWindow);
        }, true);
	}

	updateWidth = (width) => {
        if (width !== this.state.width) {
            this.setState({
                width: width
            });
        }
    }

	render() {
        return (  
            <div>
                <HeaderTop />
                <main className="cd-main-content" style={{width: this.state.width}}>
                    <ContactForm />
                    <SectionFooter />
                </main>
                <SectionScrollToUp />
            </div>
        );
    }
    
}

export default HomePage;