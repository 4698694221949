import React, { Component } from 'react';

class SectionFaqs extends Component {
    render() {
        return (
            <section className="home section grey-section section-padding-bottom section-home-padding-top" style={{display: 'inline-table', paddingTop: '80px'}}>
                <div className="container">
                    <div className="sixteen columns" style={{width: '100%'}}>
                        <div className="section-title">
                            <h1>ENIGMAS FRECUENTES</h1>
                            <div className="subtitle big">AQUÍ ENCONTRARÁS LAS RESPUESTAS</div>
                        </div>
                    </div>
                    <div className="sixteen columns" style={{width: '100%'}}>
                        <div className="accordion">
                            <div className="accordion_in" data-scroll-reveal="enter left move 200px over 1s after 0.3s">
                                <div className="acc_head white-section" style={{paddingLeft: '20px', paddingRight: '60px'}}>El envío a domicilio, ¿es gratis?</div>
                                <div className="acc_content white-section">
                                <p>
                                    A cualquier parte del país:<br/>
                                    &nbsp;&nbsp;- Para compras mayores a <b>$ 3.000</b>, el envio es <b>GRATIS</b>.<br/>
                                    &nbsp;&nbsp;- Para compras menores a <b>$ 3.000</b>, tiene un costo de <b>$200</b>.
                                </p>
                                </div>
                            </div>

                            <div className="accordion_in" data-scroll-reveal="enter right move 200px over 1s after 0.3s">
                                <div className="acc_head white-section" style={{paddingLeft: '20px', paddingRight: '60px'}}>Una vez que realicé el pago, ¿cuánto tarda el envío?</div>
                                <div className="acc_content white-section">
                                <p>
                                    Dentro de Montevideo: Recibirás tu compra en menos de <b>24 horas hábiles</b>.<br/>
                                    Resto del país: Recibirás tu compra dentro de los próximos <b>3 días hábiles</b>.
                                </p>
                                </div>
                            </div>

                            <div className="accordion_in" data-scroll-reveal="enter right move 200px over 1s after 0.3s">
                                <div className="acc_head white-section" style={{paddingLeft: '20px', paddingRight: '60px'}}>¿Qué pasa si me surge un imprevisto y no hay nadie en mi domicilio en el momento de la entrega?</div>
                                <div className="acc_content white-section">
                                <p>
                                    Si no hay nadie en el domicilio al momento de realizar la entrega, dejaremos una nota y te contactaremos para coordinarla nuevamente.<br/> 
                                    Podrás optar por:<br/>
                                    - Retirar el producto en un punto de pick up.<br/>
                                    - Coordinar nuevamente el envío, el cual tendrá un costo de $200 a abonar al momento de recibirlo.<br/><br/>
                                </p>
                                </div>
                            </div>
                        
                            <div className="accordion_in" data-scroll-reveal="enter left move 200px over 1s after 0.3s">
                                <div className="acc_head white-section" style={{paddingLeft: '20px', paddingRight: '60px'}}>¡Lo quiero ya! ¿Puedo pasar a retirarlo?</div>
                                <div className="acc_content white-section">
                                <p>Sí! Tenemos nuestra tienda física en <a style={{textTransform: "none"}} target="_blank" href="https://goo.gl/maps/MpvT1nfRh7JmbD9EA">José Ellauri 969, Montevideo</a>. El horario de la tienda es Lunes a Viernes de 14:00 a 20:00 horas y Sábados de 11:00 a 17:00 horas.</p>
                                </div>
                            </div>

                            <div className="accordion_in" data-scroll-reveal="enter right move 200px over 1s after 0.3s">
                                <div className="acc_head white-section" style={{paddingLeft: '20px', paddingRight: '60px'}}>¿Qué métodos de pago puedo utilizar?</div>
                                <div className="acc_content white-section">
                                <p>
                                <div>A la hora de hacer tu compra, podrás elegir entre las siguientes opciones:</div><br/>
                                    <b>Mercado Pago:</b><br/>
                                    &nbsp;&nbsp;- Tarjetas de débito (VISA)<br/>
                                    &nbsp;&nbsp;- Tarjetas de crédito (VISA, OCA, Mastercard, Diners Club y Líder)<br/>
                                    &nbsp;&nbsp;- Dinero en efectivo a través de redes de cobranza (Abitab, Red Pagos)<br/><br/>
                                    <b>Efectivo, Transferencia o Tarjetas VISA, Mastercard, Oca, Maestro y otras </b> en nuestra tienda física.
                                </p>
                                </div>
                            </div>
                        
                            <div className="accordion_in" data-scroll-reveal="enter left move 200px over 1s after 0.3s">
                                <div className="acc_head white-section" style={{paddingLeft: '20px', paddingRight: '60px'}}>¿Puedo realizar el pago en cuotas?</div>
                                <div className="acc_content white-section">
                                <p>¡Sí! Aceptamos múltiples medios de pago que ofrecen hasta 12 cuotas sin interés.</p>
                                </div>
                            </div>

                            <div className="accordion_in" data-scroll-reveal="enter left move 200px over 1s after 0.3s">
                                <div className="acc_head white-section" style={{paddingLeft: '20px', paddingRight: '60px'}}>Quiero comprar un juego que no está en el catálogo, ¿qué debo hacer?</div>
                                <div className="acc_content white-section">
                                <p>
                                    Contanos cuál es! Te confirmaremos si podemos conseguirlo, cuál es el precio y la fecha de entrega estimada. Podrás reservarlo con una seña del 25% del valor.
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    
            </section>
        );
    }
}

export default SectionFaqs;