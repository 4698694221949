import React, { Component } from 'react';
import { NotificationContainer } from 'react-notifications';

class HeaderTop extends Component {

    constructor(){
        super();
        this.state = {
            cartItems: 0
        }
	}
    
    componentDidUpdate(prevProps) {
        if (prevProps.dataCart !== this.props.dataCart) {
            var json = localStorage.getItem("cartItems")
            if (json !== undefined && json !== null) {
                var list = JSON.parse(json);
                var quantiy = 0;
                list.forEach(item => {
                    quantiy += item.quantity
                });
                this.setState({cartItems: quantiy});
            }
        }
    }

	componentWillMount() {
        var json = localStorage.getItem("cartItems")
        if (json !== undefined && json !== null) {
            var list = JSON.parse(json);
            var quantiy = 0;
            list.forEach(item => {
                quantiy += item.quantity
            });
            this.setState({cartItems: quantiy});
        }
    }
    
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            var text = document.getElementById("search").value;
            text = this.removeSpecialCharacter(text);
            localStorage.setItem("search", Buffer.from(text).toString('base64'));
            window.location.href = "/shop";
        }
    }

    removeSpecialCharacter = (text) => {
        let value = text.toLowerCase();
        value = value.replaceAll('á', 'a');
        value = value.replaceAll('é', 'e');
        value = value.replaceAll('í', 'i');
        value = value.replaceAll('ó', 'o');
        value = value.replaceAll('ú', 'u');
        value = value.replace(/[&\/\\#,+()$~%.'":*?<>{}!¿?¡]/g, '');
        return value
    }

    showFilter = () => {
        if (this.props.showFilter === true) {
            return 'inline-block';
        } else {
            return 'none';
        }
    }

    render() {
        var dark = false;
        if (this.props.dark !== undefined) {
            dark = this.props.dark;
        }
        return (
            <div className="header-top">
                <header className="cd-main-header">
                    <a className="cd-logo animsition-link" href="/">
                        <img className="logo-img" src="/logo.svg" alt="Logo" />
                    </a>

                    <ul className="cd-header-buttons">
                        <li className="filter-item" style={{display: this.showFilter()}}>
                            <a data-toggle="modal" data-target="#filterModal" href="/" className="fa fa-fw"></a>
                        </li>
                        <li><a className="cd-search-trigger" href="#cd-search"><span></span></a></li>
                        <li><a className="cd-nav-trigger" href="#cd-primary-nav"><span></span></a></li>
                    </ul>
                </header>

                <nav className="cd-nav">
                    <ul id="cd-primary-nav" className="cd-primary-nav is-fixed">
                        <li><a href="/" className="animsition-link">Inicio</a></li>
                        <li><a href="/shop" className="animsition-link">Tienda</a></li>
                        <li><a href="/faqs" className="animsition-link">FAQs</a></li>
                        <li><a href="/contact" className="animsition-link">Contacto</a></li>
                        <li>
                            <a href="/cart" className="animsition-link">
                                <i className="fa" style={{fontSize: '20px'}}>&#xf07a;</i>
                            </a>
                            <span className='badge' style={{display: (this.state.cartItems === 0)?'none':'block'}}> {this.state.cartItems} </span>
                        </li>
                    </ul>
                </nav>

                <div id="cd-search" className="cd-search">
                    <input id="search" type="search" placeholder="Buscar..." onKeyDown={(e) => this.handleKeyDown(e)} style={{fontSize: '15px'}}/>
                </div>

                <NotificationContainer/>
            </div>
        );
    }
}

export default HeaderTop;