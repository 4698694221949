import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';
import FaqPage from './pages/FaqPage';
import CartPage from './pages/CartPage';
import ShopPage from './pages/ShopPage';
import ShopItemPage from './pages/ShopItemPage';
import CheckoutPage from './pages/CheckoutPage';
import PaymentPage from './pages/PaymentPage';
import ResultPurchasePage from './pages/ResultPurchasePage';

import config from './config.json';

import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/analytics';

const firebaseConfig = {
  apiKey: config.FIREBASE_API_KEY,
  authDomain: config.FIREBASE_AUTH_DOMAIN,
  projectId: config.FIREBASE_PROJECT_ID,
  storageBucket: config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
  appId: config.FIREBASE_APP_ID,
  measurementId: config.FIREBASE_MEASUREMENT_ID 
}
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={"/"} component={HomePage} />
        <Route exact path={"/contact"} component={ContactPage} />
        <Route exact path={"/faqs"} component={FaqPage} />
        <Route exact path={"/cart"} component={CartPage} />
        <Route exact path={"/shop"} component={ShopPage} />
        <Route exact path={"/checkout"} component={CheckoutPage} />
        <Route exact path={"/payment"} component={PaymentPage} />
        <Route exact path={"/result"} component={ResultPurchasePage} />
        
        <Route path={"/item"} component={ShopItemPage} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;