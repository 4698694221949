import React, { Component } from 'react';

class SectionCategory extends Component {
    render() {
        return (
            <section style={{paddingTop: '70px'}} className="section grey-section section-padding-top-bottom" id="scroll-link">
                <div className="sixteen columns">
                    <div className="section-title" style={{paddingLeft: '15px', paddingRight:'15px'}}>
                        <h3>Si buscás diversión, ¡estás en el lugar correcto!</h3>
                        <div className="subtitle">Nuestra misión es cuidar cada detalle, para que la tuya solo sea <span className="subtitle-written">jugar</span>.</div>
                    </div>
                </div>
                
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <div className="services-boxes-2">
                                <div className="icon-box">&#xf086;</div>
                                <h6>JUEGOS EN ESPAÑOL</h6>
                                <p>Te ofrecemos los mejores juegos en tu idioma.</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className="services-boxes-2">
                                <div className="icon-box">&#xf09d;</div>
                                <h6>MÉTODOS DE PAGO</h6>
                                <p>Hacé tu compra con el medio de pago que prefieras.</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className="services-boxes-2">
                                <div className="icon-box">&#xf135;</div>
                                <h6>ENVÍO GRATIS</h6>
                                <p>A todo el país en compras mayores a $3.000.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default SectionCategory;