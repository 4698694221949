import React, { Component } from 'react';

class ContactTop extends Component {
    render() {
        return (
            <section className="section parallax-section parallax-section-padding-top-bottom-pagetop section-page-top-title">
                <div className="parallax-contact"></div>
            </section>
        );
    }
}

export default ContactTop;