import React, { Component } from 'react';

import HeaderTop from '../components/HeaderTop';
import SectionResultPurchase from '../components/SectionResultPurchase';
import SectionScrollToUp from '../components/SectionScrollToUp';
import SectionFooter from '../components/SectionFooter';

class ResultPurchasePage extends Component {

    constructor(){
        super();
        this.state = {
            result: "", 
            shippingType:""
        }
    }

    componentWillMount() {  
        let search = this.props.location.search
        let result = new URLSearchParams(search).get("result")
        let shippingType = new URLSearchParams(search).get("shippingType")
        this.setState({"result": result,"shippingType":shippingType})
        if (result == "success" || result == "pending") {
            localStorage.removeItem("cartItems");
        }
    }

	render() {           
        return (  
            <div>
                <HeaderTop />
                <main className="cd-main-content">        
                    <SectionResultPurchase result={this.state}/>
                    <SectionFooter />
                </main>
                <SectionScrollToUp />
            </div>
        );
    }
    
}

export default ResultPurchasePage;