import React, { Component } from 'react';

class SectionShopFilters extends Component {

    constructor(){
        super();
        const widthWindow = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.state = {
            width: widthWindow,
            selectedType: undefined,
            selectedPlayers: undefined
        }

        var context = this;
        window.addEventListener('resize', function(event) {
            const widthWindow  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            context.updateWidth(widthWindow);
        }, true);
	}

    onChangeInput = (e) => {
        var input = e.target.value;
        this.props.onChangeInput(input)
    }

    updateWidth = (width) => {
        if (width !== this.state.width) {
            this.setState({
                width: width
            });            
        }
    }

    selectType = (e) => {
        if (e.target.checked) {
            this.setState({selectedType: parseInt(e.target.id)})
            this.props.onSelectType(parseInt(e.target.id))
        } else {
            this.setState({selectedType: undefined})
            this.props.onSelectType(undefined)
        }
    }

    selectPlayers = (e) => {
        if (e.target.checked) {
            this.setState({selectedPlayers: parseInt(e.target.id)})
            this.props.onSelectPlayers(parseInt(e.target.id))
        } else {
            this.setState({selectedPlayers: undefined})
            this.props.onSelectPlayers(undefined)
        }
    }

    renderPrices = () => {
        if (this.state.width <= 500 || this.state.width >= 1170) {
            return (
                <ul className="link-recents">
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-4" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121', alignItems:'center', display:'flex'}}>
                                    Desde
                                </div>
                                <div className="col-8" style={{paddingRight:'30px'}}>
                                    <input id="from" className="input-code-shop" placeholder="Precio" onChange={(e) => this.props.onChangeMinPrice(e)} style={{paddingTop: '6px',paddingBottom: '6px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-4" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121', alignItems:'center', display:'flex'}}>
                                Hasta
                                </div>
                                <div className="col-8" style={{paddingRight:'30px'}}>
                                    <input id="to" className="input-code-shop" placeholder="Precio" onChange={(e) => this.props.onChangeMaxPrice(e)} style={{paddingTop: '6px',paddingBottom: '6px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="container" style={{width: '100%'}}>
                        <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                            <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                En stock
                            </div>
                            <div className="col-3">
                                <input type="checkbox" id="stock" className="input-shop" onChange={(e) => this.props.onSelectStock(e)} style={{marginTop:'1px'}}/>
                            </div>
                        </div>
                    </li>
                </ul>
            );
        } else {
            return (
                <ul className="link-recents">
                    <li className="container" style={{width: '100%'}}>
                        <div className="row">
                            <div className="col-6">
                                <div className="container" style={{padding: '0', width: '100%'}}>
                                    <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                        <div className="col-3" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121', alignItems:'center', display:'flex'}}>
                                            Desde
                                        </div>
                                        <div className="col-6" style={{paddingRight:'30px'}}>
                                            <input id="from" className="input-code-shop" placeholder="Precio" onChange={(e) => this.props.onChangeMinPrice(e)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="container" style={{padding: '0', width: '100%'}}>
                                    <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                        <div className="col-3" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121', alignItems:'center', display:'flex'}}>
                                            Hasta
                                        </div>
                                        <div className="col-6" style={{paddingRight:'30px'}}>
                                            <input id="to" className="input-code-shop" placeholder="Precio" onChange={(e) => this.props.onChangeMaxPrice(e)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="container" style={{width: '100%'}}>
                        <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                            <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                En stock
                            </div>
                            <div className="col-3">
                                <input type="checkbox" id="stock" className="input-shop" onChange={(e) => this.props.onSelectStock(e)} style={{marginTop:'1px'}}/>
                            </div>
                        </div>
                    </li>
                </ul>
            );
        }
    }
    
    render() {
        return (
            <div className="post-sidebar">
                <input style={{paddingTop: "10px", paddingBottom: "10px"}} id="searchInput" type="text" placeholder="Buscar" defaultValue={this.props.defaultSearch} onChange={(e) => this.onChangeInput(e)}/>
                <div className="separator-sidebar"></div>

                <h6>Precio</h6>
                { this.renderPrices() }

                <div className="separator-sidebar"></div>

                <h6>Categorías</h6>
                <ul className="link-recents">
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Euro
                                </div>
                                <div className="col-3">
                                    <input type="checkbox" id="1" className="input-shop" onChange={(e) => this.props.onSelectCategory(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Party
                                </div>
                                <div className="col-3">
                                    <input type="checkbox" id="2" className="input-shop" onChange={(e) => this.props.onSelectCategory(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Familiar
                                </div>
                                <div className="col-3">
                                    <input type="checkbox" id="3" className="input-shop" onChange={(e) => this.props.onSelectCategory(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Abstractos
                                </div>
                                <div className="col-3">
                                <input type="checkbox" id="4" className="input-shop" onChange={(e) => this.props.onSelectCategory(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Temáticos
                                </div>
                                <div className="col-3">
                                    <input type="checkbox" id="5" className="input-shop" onChange={(e) => this.props.onSelectCategory(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Investigación
                                </div>
                                <div className="col-3">
                                    <input type="checkbox" id="6" className="input-shop" onChange={(e) => this.props.onSelectCategory(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="separator-sidebar"></div>

                <h6>Cantidad de jugadores</h6>
                <ul className="link-recents">
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Uno
                                </div>
                                <div className="col-3">
                                    <input type="checkbox" id="1" className="input-shop" checked={this.state.selectedPlayers === 1} onChange={(e) => this.selectPlayers(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Dos
                                </div>
                                <div className="col-3">
                                <input type="checkbox" id="2" className="input-shop" checked={this.state.selectedPlayers === 2} onChange={(e) => this.selectPlayers(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Tres
                                </div>
                                <div className="col-3">
                                    <input type="checkbox" id="3" className="input-shop" checked={this.state.selectedPlayers === 3} onChange={(e) => this.selectPlayers(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Cuatro
                                </div>
                                <div className="col-3">
                                    <input type="checkbox" id="4" className="input-shop" checked={this.state.selectedPlayers === 4} onChange={(e) => this.selectPlayers(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Cinco o más
                                </div>
                                <div className="col-3">
                                    <input type="checkbox" id="5" className="input-shop" checked={this.state.selectedPlayers === 5} onChange={(e) => this.selectPlayers(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="separator-sidebar"></div>

                <h6>Tipo</h6>
                <ul className="link-recents">
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Cartas
                                </div>
                                <div className="col-3">
                                    <input type="checkbox" id="2" className="input-shop" checked={this.state.selectedType === 2} onChange={(e) => this.selectType(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Tablero
                                </div>
                                <div className="col-3">
                                    <input type="checkbox" id="1" className="input-shop" checked={this.state.selectedType === 1} onChange={(e) => this.selectType(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="separator-sidebar"></div>

                <h6>Nivel de dificultad</h6>
                <ul className="link-recents">
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Bajo
                                </div>
                                <div className="col-3">
                                    <input type="checkbox" id="1" className="input-shop" onChange={(e) => this.props.onSelectDifficulty(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Medio
                                </div>
                                <div className="col-3">
                                    <input type="checkbox" id="2" className="input-shop" onChange={(e) => this.props.onSelectDifficulty(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Alto
                                </div>
                                <div className="col-3">
                                    <input type="checkbox" id="3" className="input-shop" onChange={(e) => this.props.onSelectDifficulty(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="separator-sidebar"></div>
                
                <h6>Otros</h6>
                <ul className="link-recents">
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Expansión
                                </div>
                                <div className="col-3">
                                    <input type="checkbox" id="expansion" className="input-shop" onChange={(e) => this.props.onSelectOther(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Cooperativo
                                </div>
                                <div className="col-3">
                                    <input type="checkbox" id="cooperativo" className="input-shop" onChange={(e) => this.props.onSelectOther(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Solitario
                                </div>
                                <div className="col-3">
                                    <input type="checkbox" id="solitario" className="input-shop" onChange={(e) => this.props.onSelectOther(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="container" style={{width: '100%'}}>
                        <div className="container" style={{padding: '0', width: '100%'}}>
                            <div className="row" style={{marginRight: '-30px', marginTop: '7px', marginBottom: '7px'}}>
                                <div className="col-9" style={{fontSize: '11px', textTransform: 'uppercase', color: '#212121'}}>
                                    Ideales para dos
                                </div>
                                <div className="col-3">
                                    <input type="checkbox" id="para_dos" className="input-shop" onChange={(e) => this.props.onSelectOther(e)} style={{marginTop:'1px'}}/>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        );
    }
}

export default SectionShopFilters;